import React, { useEffect, useState } from "react";
import FiltersApiService from "../../../services/FiltersApiService";
import AuthHelper from "../../../services/auth-helper";
import {
    Card,
    CardHeader,
    CardBody,
    Flex,
    Button,
    Text,
    Loader,
    TrashCanIcon,
    SettingsAudioIcon,
    DownloadIcon,
    Dialog,
    Tooltip,
    Segment,
    ExclamationTriangleIcon,
    Avatar,
    Dropdown,
    MenuButton    
} from "@fluentui/react-northstar";
import { ExpandIcon, LinkIcon, ApprovalsAppbarIcon, CollapseIcon, InfoIcon, TranscriptIcon, LeaveIcon, EditIcon  } from '@fluentui/react-icons-northstar';
import {
  BarChart,
  LineChart,
  BasicSummary,
  UserComparisonView,
  ComparisonView,
  DonutChart,
  GroupedGrid,
  MeetingHabitsIndicator,
  MeetingSummaryView,
  PieChart,
  ReactionsView,
  StackedBarChart,
  StandardGrid,
  TeamSummaryView,
  TopUsersGrid,
  MeetingParticipation,
  UserSummaryView,
  UserAnalytics,
  CallQueueSummaryView,
  ExpandableRowGrid,
  ColumnChart,
  TeamEngagementView,
  StackedBarChartAndGroupedGrid,
  DirectReportOverview,
  OnetoOneOverview,
  OrganizationalChart,
  ManagementInsights,
  GridFilteredByInsight,
  FreemiumCardView
} from "../../viewTypes/index";
import MockData from "../../viewTypes/mockData";
import MaximizableView from "../../Shared/uicomponents/MaximizableView";
import useFullscreenStatus from "../../Shared/CustomHooks/useFullScreenStatus";
import './card.scss';
import { cardFilters } from "../../filters/card/CardFiltersData/CardFiltersData";
import { Calendar } from "@material-ui/pickers";
import * as moment from "moment";
import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";
import { set } from "lodash";
import html2canvas from 'html2canvas';
import { cardSize } from "../../pages/Dashboard/allwidgets";
import { DropDownButton } from "@progress/kendo-react-buttons";
import copy from "copy-to-clipboard";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { createPortal } from "react-dom";
import { SavePDFConsumer, SavePDFProvider } from "../../Shared/Context/ExploreAppMode";
import WindowContext from "../../Shared/Context/Context";

const WidgetPreview = (props) => {
  
  const data = MockData[props.widgetID].data;
  // console.log(MockData[props.widgetID].data)   
  return (
    <Flex
      style={{ minHeight: "350px", height: "100%" }}
      column
      fluid="true"
      gap="gap.small"
      hAlign="stretch"
    >
          <Text style={{ whiteSpace: 'break-spaces' }} content={MockData[props.widgetID].description} />
      <Flex.Item>
        <Segment style={{ overflow: "auto", height: "100%", padding: "0" }}>
          {
            {
              0: <StandardGrid data={data} widgetId={props.widgetID} />,
              2: <GroupedGrid data={data} widgetId={props.widgetID} />,
              3: <MeetingSummaryView data={data} />,
              4: <MeetingHabitsIndicator data={data} />,
              5: <DonutChart data={data} />,
              6: <ReactionsView data={data} />,
              7: <BarChart data={data} widgetId={props.widgetID}/>,
              8: <DonutChart data={data} />,
              9: <TopUsersGrid data={data} widgetId={props.widgetID} />,
              10: <TeamSummaryView data={data} />,
              11: <MeetingParticipation data={data} />,
              12: <BasicSummary data={data} />,
              13: <UserSummaryView data={data} />,
              15: <UserComparisonView data={data} />,
              16: <ComparisonView data={data} />,
              18: <StackedBarChart data={data} widgetId={props.widgetID}/>,
              19: <PieChart data={data} />,
              20: <CallQueueSummaryView data={data}/>,
              21: <StackedBarChartAndGroupedGrid data={data} widgetId={props.widgetID} />,
              22: <TeamEngagementView data={data} widgetId={props.widgetID} />,
              23: <ExpandableRowGrid data={data} widgetId={props.widgetID} />,
              24: <OnetoOneOverview data={data} widgetId={props.widgetID} />,
              25: <ManagementInsights data={data} />,
              26: <ColumnChart data={data} widgetId={props.widgetID} />,
              27: <DirectReportOverview data={data} widgetId={props.widgetID} />,
              28: <OrganizationalChart data={data} widgetId={props.widgetID} />,
              29: <GridFilteredByInsight data={data} widgetId={props.widgetID} />,
              31:<LineChart data={data.data} />

              // 20: <UserAnalytics data={data} />,
            }[props.viewTypeID]
          }
        </Segment>
      </Flex.Item>
    </Flex>
  );
};

const CardLivePreview = ({ livePreviewData, demo }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState({});
    const [isDemo, setIsDemo] = useState(false);
    const [noData, setNoData] = useState(false);
    let mockData = MockData[livePreviewData.cardWidgetID].data;

    useEffect(() => {
        if (
            livePreviewData.data === null ||
            livePreviewData.data === "" ||
            (Array.isArray(livePreviewData.data) && livePreviewData.data.length === 0) ||
            (typeof livePreviewData.data === "object" && livePreviewData.data.categories === null)
        ) {
            setNoData(true);
            setIsLoaded(true);

            // setData(response);
        } else {
            setNoData(false);
            setData(livePreviewData.data);
            setIsLoaded(true);
            // console.log(livePreviewData);
        }
    }, [livePreviewData]);

    useEffect(() => {
        setIsDemo(demo);
    }, [demo]);

  return (
    <Card compact style={{width: '100%'}}>
      <CardHeader>
        <Flex
          vAlign="center"
          className="cardDesc"
          space="between"
          style={{ margin: "6px 0px 7px 10px", width: "100%" }}
        >
          <Tooltip className="tooltipTxt"
            trigger={<Text content={livePreviewData.name} weight="bold" truncated />}
            align="center"
            position="below"
            content={livePreviewData.name}
          />
        </Flex>
      </CardHeader>
      <CardBody>
        { isDemo ? (
          {
            0: <StandardGrid data={mockData} widgetId={livePreviewData.cardWidgetID} />,
            2: <GroupedGrid data={mockData} widgetId={livePreviewData.cardWidgetID} />,
            3: <MeetingSummaryView data={mockData} />,
            4: <MeetingHabitsIndicator data={mockData} />,
            5: <DonutChart data={mockData} />,
            6: <ReactionsView data={mockData} />,
            7: <BarChart data={mockData} widgetId={livePreviewData.cardWidgetID}/>,
            8: <DonutChart data={mockData} />,
            9: <TopUsersGrid data={mockData} widgetId={livePreviewData.cardWidgetID} />,
            10: <TeamSummaryView data={mockData} />,
            11: <MeetingParticipation data={mockData} />,
            12: <BasicSummary data={mockData} />,
            13: <UserSummaryView data={mockData} />,
            15: <UserComparisonView data={mockData} />,
            16: <ComparisonView data={mockData} />,
            18: <StackedBarChart data={mockData} widgetId={livePreviewData.cardWidgetID}/>,
            19: <PieChart data={mockData} />,
            20: <CallQueueSummaryView data={mockData}/>,
            21: <StackedBarChartAndGroupedGrid data={mockData} widgetId={livePreviewData.cardWidgetID} />,
            22: <TeamEngagementView data={mockData} widgetId={livePreviewData.cardWidgetID} />,
            23: <ExpandableRowGrid data={mockData} widgetId={livePreviewData.cardWidgetID} />,
            24: <OnetoOneOverview data={mockData} widgetId={livePreviewData.cardWidgetID} />,
            25: <ManagementInsights data={mockData} />,
            26: <ColumnChart data={mockData} widgetId={livePreviewData.cardWidgetID}/>,
            27: <DirectReportOverview data={mockData} widgetId={livePreviewData.cardWidgetID} />,
            28: <OrganizationalChart data={mockData} widgetId={livePreviewData.cardWidgetID} />,
            29: <GridFilteredByInsight data={mockData} widgetId={livePreviewData.cardWidgetID} />,
            31:<LineChart data={data.data} />

            // 20: <UserAnalytics data={data} />,
          }[livePreviewData.viewTypeID]
        ) : isLoaded ? (
          !noData ? (
            {
              0: <StandardGrid data={data} widgetId={livePreviewData.cardWidgetID} />,
              2: <GroupedGrid data={data} widgetId={livePreviewData.cardWidgetID} />,
              3: <MeetingSummaryView data={data} />,
              4: <MeetingHabitsIndicator data={data} />,
              5: <DonutChart data={data} />,
              6: <ReactionsView data={data} />,
              7: <BarChart data={data} widgetId={livePreviewData.cardWidgetID}/>,
              8: <DonutChart data={data} />,
              9: <TopUsersGrid data={data} widgetId={livePreviewData.cardWidgetID} />,
              10: <TeamSummaryView data={data} />,
              11: <MeetingParticipation data={data} />,
              12: <BasicSummary data={data} />,
              13: <UserSummaryView data={data} />,
              15: <UserComparisonView data={data} />,
              16: <ComparisonView data={data} />,
              18: <StackedBarChart data={data} widgetId={livePreviewData.cardWidgetID}/>,
              19: <PieChart data={data} />,
              20: <CallQueueSummaryView data={data}/>,
              21: <StackedBarChartAndGroupedGrid data={data} widgetId={livePreviewData.cardWidgetID} />,
              22: <TeamEngagementView data={data} widgetId={livePreviewData.cardWidgetID} />,
              23: <ExpandableRowGrid data={data} widgetId={livePreviewData.cardWidgetID} />,
              24: <OnetoOneOverview data={data} widgetId={livePreviewData.cardWidgetID} />,
              25: <ManagementInsights data={data} />,
              26: <ColumnChart data={data} widgetId={livePreviewData.cardWidgetID}/>,
              27: <DirectReportOverview data={data} widgetId={livePreviewData.cardWidgetID} />,
              28: <OrganizationalChart data={data} widgetId={livePreviewData.cardWidgetID} />,
              29: <GridFilteredByInsight data={data} widgetId={livePreviewData.cardWidgetID} />,
              31:<LineChart data={data.data} />


              // 20: <UserAnalytics data={data} />,
            }[livePreviewData.viewTypeID]
          ) : (
            <Flex vAlign="center" fill hAlign="center">
              <Text size="large" content="No data to display." />
            </Flex>
          )
        ) : (
          <Loader style={{ height: "100%" }} />
        )}
      </CardBody>
    </Card>
  );
};

Date.prototype.AddDays = function (days) {
    return (new Date(this.setDate(this.getDate() + days)));
}
Date.prototype.GetFirstDayOfWeek = function () {
    return (new Date(this.setDate(this.getDate() - this.getDay() + (this.getDay() == 0 ? -6 : 1))));
}
Date.prototype.GetFirstDayOfMonth = function () {
    return (new Date(this.setDate(1)));
}


const CardWidget = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState({});
    const [operating, setOperating] = useState(false);
    const [noData, setNoData] = useState(false);
    const [isDemo, setIsDemo] = useState(false);
    let mockData = MockData[props.card.widgetID]?.data;
    const [_selectedDate, setSelectedDate] = useState(props.selectedDate);
    const [isInfoView, setInfoView] = useState(false);
    const [isInfoViewAvailable, setInfoViewAvailable] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [appliedFiltersForInfo, setAppliedFiltersForInfo] = useState([]);
    const [isLicenseView, setIsLicenseView] = useState(false);
    const [isLicenseWarningAvailable, setIsLicenseWarningAvailable] = useState(false);
    const [invalidLicenseUsers, setInvalidLicenseUsers] = useState([]);

    const [cardLayout, setCardLayout] = useState({ w: 1, h: 1 });
    const [deletingLoader, setDeletingLoader] = useState(false);
    const [downloadCard, setDownloadCard] = useState(false);
    const [downloadCardCSV, setDownloadCardCSV] = useState(false);
    const [copied, setCopied] = useState(false);
    const [isNotPDF, setIsNotPDF] = useState(false);
    const [yesPDF, setYesPDF] = useState(false);
    const allWidgets = props.addCardsList;
    let _date = props.selectedDate;
    let cardTimer;
    const widget_filterIds = allWidgets.reduce((acc, curr) => (acc[curr.cardWidgetID] = cardFilters[curr.cardFilterID], acc), {});
    const maximizableElement = React.useRef(null);
    let isFullscreen, setIsFullscreen;
    let errorMessage;

    try {
        [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
    } catch (e) {
        errorMessage = "Fullscreen not supported";
        isFullscreen = false;
        setIsFullscreen = undefined;
    }

    function prepareInvalidLicenseData(_inValidLicenses) {
        setInvalidLicenseUsers(_inValidLicenses);
    }

    function prepareInfoData(_appliedFilters) {    
            
       let applied = [];
        if (_appliedFilters) {
            let _fromDate = null;
            let toDate = _appliedFilters.selectedEndDate === null ? new Date() : new Date(_appliedFilters.selectedEndDate);//new Date(props.selectedDate);
            _fromDate = _appliedFilters.selectedEndDate === null ? new Date() : new Date(_appliedFilters.selectedEndDate);
            const today = new Date();
            const quarter = Math.floor((today.getMonth() / 3));
            switch (_appliedFilters.runPeriod) {
                case 1:   //this week        
                    _fromDate = _fromDate.GetFirstDayOfWeek();
                    break;
                case 2: //this month
                    _fromDate = _fromDate.GetFirstDayOfMonth();
                    break;
                case 3: //Last 7 Days
                    _fromDate = _fromDate.AddDays(-6);
                    break;
                case 4: //Last 30 Days
                    _fromDate = _fromDate.AddDays(-30);//setMonth(_fromDate.getMonth() - 1)
                    break;
                case 5://Custom Date
                    _fromDate = new Date(_appliedFilters.selectedStartDate);
                    break;
                case 6://Yesterday
                    _fromDate = _fromDate.AddDays(-1);
                    toDate = toDate.AddDays(-1);
                    break;
                case 7://Last Week
                    _fromDate = _fromDate.GetFirstDayOfWeek();
                    _fromDate = _fromDate.AddDays(-7);
                    toDate = _fromDate.AddDays(6);
                    _fromDate = _fromDate.AddDays(-6);
                    break;
                case 8: //Last Month
                    _fromDate = _fromDate.GetFirstDayOfMonth();
                    toDate = _fromDate.AddDays(-1);
                    _fromDate = _fromDate.GetFirstDayOfMonth();
                    break;
                case 9: // This Quarter
                    _fromDate = new Date(today.getFullYear(), quarter * 3, 1);
                    toDate = new Date(_fromDate.getFullYear(), _fromDate.getMonth() + 3, 0);
                    break;
                case 10: //Last Quarter
                    _fromDate = new Date(today.getFullYear(), quarter * 3 - 3, 1);
                    toDate = new Date(_fromDate.getFullYear(), _fromDate.getMonth() + 3, 0);
                    break;
               
            }
            if (_fromDate) {
                applied.push({
                    header: 'Period',
                    value: new Date(_fromDate).toDateString().substr(4) + ' - ' + new Date(toDate).toDateString().substr(4)// _fromDate.toDateString().substr(4)
                });
            }
            else {
                applied.push({ header: 'Period', value: new Date(_appliedFilters.selectedStartDate).toDateString().substr(4) });
            }

            switch (_appliedFilters.timePeriod) {
                case 0:
                    applied.push({ header: 'Time', value: 'Working Hours' });
                    break;
                case 1:
                    applied.push({ header: 'Time', value: 'Outside Working Hours' });
                    break;
                case 2:
                    applied.push({ header: 'Time', value: _appliedFilters.selectedStartTime + ' - ' + _appliedFilters.selectedEndTime });
                    break;
            }
            if (!(_appliedFilters.userFilter == null || _appliedFilters.userFilter == "")) {
                _appliedFilters.userFilter = _appliedFilters.userFilter.split(',').map(Number);
                let _valueArray = props.userFiltersData.userFilter.items.filter(u => _appliedFilters.userFilter.includes(u.value));
                let _count = _valueArray.length;
                _valueArray = _valueArray.slice(0, 5);
                if ((_count - 5) > 0)
                    _valueArray.push({ value: -10, header: '+' + (_count - 5) });
                applied.push({
                    isHtml: true,
                    header: 'People',
                    value: _valueArray
                });
            }
            else if (!(_appliedFilters.teamFilter == null || _appliedFilters.teamFilter == "")) {
                _appliedFilters.teamFilter = _appliedFilters.teamFilter.split(',').map(Number);
                let _valueArray = props.userFiltersData.teamFilter.items.filter(u => _appliedFilters.teamFilter.includes(u.value));
                let _count = _valueArray.length;
                _valueArray = _valueArray.slice(0, 5);
                if ((_count - 5) > 0)
                    _valueArray.push({ value: -10, header: '+' + (_count - 5) });
                applied.push({
                    isHtml: true,
                    header: 'Teams',
                    value: _valueArray
                });
            }
            else if (!(_appliedFilters.groupFilter == null || _appliedFilters.groupFilter == "")) {
                _appliedFilters.groupFilter = _appliedFilters.groupFilter.split(',').map(Number);
                let _valueArray = props.userFiltersData.groupFilter.items.filter(u => _appliedFilters.groupFilter.includes(u.value));
                let _count = _valueArray.length;
                _valueArray = _valueArray.slice(0, 5);
                if ((_count - 5) > 0)
                    _valueArray.push({ value: -10, header: '+' + (_count - 5) });
                applied.push({
                    isHtml: true,
                    header: 'Groups',
                    value: _valueArray
                });
            }
            else if (!(_appliedFilters.callQueueFilter == null || _appliedFilters.callQueueFilter == "")) {
                _appliedFilters.callQueueFilter = _appliedFilters.callQueueFilter.split(',').map(Number);
                let _valueArray = props.userFiltersData.callQueueFilter.items.filter(u => _appliedFilters.callQueueFilter.includes(u.value));
                let _count = _valueArray.length;
                _valueArray = _valueArray.slice(0, 5);
                if ((_count - 5) > 0)
                    _valueArray.push({ value: -10, header: '+' + (_count - 5) });
                applied.push({
                    isHtml: true,
                    header: 'Call Queues',
                    value: _valueArray
                });
            }
            else if (!(_appliedFilters.agentFilter == null || _appliedFilters.agentFilter == "")) {
                _appliedFilters.agentFilter = _appliedFilters.agentFilter.split(',').map(Number);
                let _valueArray = props.userFiltersData.agentFilter.items.filter(u => _appliedFilters.agentFilter.includes(u.value));
                let _count = _valueArray.length;
                _valueArray = _valueArray.slice(0, 5);
                if ((_count - 5) > 0)
                    _valueArray.push({ value: -10, header: '+' + (_count - 5) });
                applied.push({
                    isHtml: true,
                    header: 'Agents',
                    value: _valueArray
                });
            }
            if (widget_filterIds[_appliedFilters.widgetId]) {
                if (widget_filterIds[_appliedFilters.widgetId].callQuality == 1 && !(_appliedFilters.callQualityFilter == null || _appliedFilters.callQualityFilter == "")) {
                    _appliedFilters.callQualityFilter = _appliedFilters.callQualityFilter.split(',').map(Number);
                    applied.push({
                        header: 'Quality',
                        value: props.userFiltersData.isCallQuality.items.filter(u => _appliedFilters.callQualityFilter.includes(u.value)).map(d => d.header).join(", ")
                    });
                }

                if (widget_filterIds[_appliedFilters.widgetId].modalities == 1 && !(_appliedFilters.modalitiesFilter == null || _appliedFilters.modalitiesFilter == "")) {
                    _appliedFilters.modalitiesFilter = _appliedFilters.modalitiesFilter.split(',').map(Number);
                    applied.push({
                        header: 'Types',
                        value: props.userFiltersData.isModalities.items.filter(u => _appliedFilters.modalitiesFilter.includes(u.value)).map(d => d.header).join(", ")
                    });
                }
                if (widget_filterIds[_appliedFilters.widgetId].reactions == 1 && !(_appliedFilters.reactionFilter == null || _appliedFilters.reactionFilter == "")) {
                    _appliedFilters.reactionFilter = _appliedFilters.reactionFilter.split(',').map(Number);
                    applied.push({
                        header: 'Reactions',
                        value: props.userFiltersData.isReaction.items.filter(u => _appliedFilters.reactionFilter.includes(u.value)).map(d => d.header).join(", ")
                    });
                }

                if (widget_filterIds[_appliedFilters.widgetId].sentiment == 1 && !(_appliedFilters.sentimentFilter == null || _appliedFilters.sentimentFilter == "")) {
                    _appliedFilters.sentimentFilter = _appliedFilters.sentimentFilter.split(',').map(Number);
                    applied.push({
                        header: 'Sentiments',
                        value: props.userFiltersData.isSentiment.items.filter(u => _appliedFilters.sentimentFilter.includes(u.value)).map(d => d.header).join(", ")
                    });
                    
                }
                if (widget_filterIds[_appliedFilters.widgetId].callParticipation == 1 && !(_appliedFilters.callParticipantsFilter == null || _appliedFilters.callParticipantsFilter == "")) {
                    _appliedFilters.callParticipantsFilter = _appliedFilters.callParticipantsFilter.split(',').map(Number);
                    applied.push({
                        header: 'Participation',
                        value: props.userFiltersData.callParticipantsFilter.items.filter(u => _appliedFilters.callParticipantsFilter.includes(u.value)).map(d => d.header).join(", ")
                    });
                    
                }
                if (widget_filterIds[_appliedFilters.widgetId].msgParticipation == 1 && !(_appliedFilters.msgParticipantsFilter == null || _appliedFilters.msgParticipantsFilter == "")) {
                    _appliedFilters.msgParticipantsFilter = _appliedFilters.msgParticipantsFilter.split(',').map(Number);
                    applied.push({
                        header: 'Participation',
                        value: props.userFiltersData.msgParticipantsFilter.items.filter(u => _appliedFilters.msgParticipantsFilter.includes(u.value)).map(d => d.header).join(", ")
                    });
                    
                }
                if (widget_filterIds[_appliedFilters.widgetId].joinedAs == 1 && !(_appliedFilters.joinedAsFilter == null || _appliedFilters.joinedAsFilter == "")) {
                    _appliedFilters.joinedAsFilter = _appliedFilters.joinedAsFilter.split(',').map(Number);
                    applied.push({
                        header: 'Joined As',
                        value: props.userFiltersData.joinedAsFilter.items.filter(u => _appliedFilters.joinedAsFilter.includes(u.value)).map(d => d.header).join(", ")
                    });
                    
                }
                if (widget_filterIds[_appliedFilters.widgetId].collaborationType == 1 && !(_appliedFilters.collaborationTypeFilter == null || _appliedFilters.collaborationTypeFilter == "")) {
                    _appliedFilters.collaborationTypeFilter = _appliedFilters.collaborationTypeFilter.split(',').map(Number);
                    applied.push({
                        header: 'Collaboration Type',
                        value: props.userFiltersData.collaborationTypeFilter.items.filter(u => _appliedFilters.collaborationTypeFilter.includes(u.value)).map(d => d.header).join(", ")
                    }); 

                }
            }

        }
        setAppliedFiltersForInfo(applied);
    }

    function getCardData() {
        if(props.disabled){
            setNoData(false);
            setIsLoaded(true);
            return;
        }
        AuthHelper.getAccessToken(function (token) {            
            FiltersApiService.GetCardData(
                props.card.cardID,
                token
            ).then((response) => {
                if (
                    response === null ||
                    response === "" || response.data === null ||
                    (Array.isArray(response.data) && response.data.length === 0) ||
                    (typeof response.data === "object" && response.data.categories === null)
                ) {
                    setNoData(true);
                    setIsLoaded(true);

                    // setData(response);
                } else {
                    setNoData(false);
                    setData(response);
                    setIsLoaded(true);
                }
                if (response != null && response.filters != null) {
                    try {
                        prepareInfoData(response.filters);
                        setAppliedFilters(response.filters);
                    }
                    catch (e) {
                        console.log(e);
                    }
                    setInfoViewAvailable(true)
                }
                else {
                    setInfoViewAvailable(false)
                    setInfoView(false)
                }

                if (response != null && response.inValidLicenses != null && response.inValidLicenses.length > 0) {
                    prepareInvalidLicenseData(response.inValidLicenses);
                    setIsLicenseWarningAvailable(true)
                }
                else {
                    setIsLicenseWarningAvailable(false)
                    setIsLicenseView(false)
                }

                // console.log(response);
            });
        })
    }

    useEffect(() => {
        setDownloadCard(false);
        clearInterval(cardTimer);
        getCardData();
        cardTimer = setInterval(function () {
            getCardData();
        }, 900000);
        return () => clearInterval(cardTimer);
    }, []);

    useEffect(() => {
      setOperating(props.operating);
    }, [props.operating]);

    useEffect(() => {
        setIsDemo(props.demo);
    }, [props.demo]);

    useEffect(() => {
        if (global.localStorage) {
            var _layout = JSON.parse(localStorage.getItem('layout' + props.dashboardId));
            if (_layout != null) {
                let _currentLayout = _layout[props.currentBreakpoint == 'xs' ? 'xxs' : props.currentBreakpoint];
                let _cardLayout = _currentLayout.find(c => c.i == props.card.cardID);
                setCardLayout({ w: _cardLayout.h == 2 ? 1 : Math.min(_cardLayout.w / 3, 3), h: _cardLayout.h });
            }
        }
        if(props.card.widgetID === 15 || props.card.widgetID === 29 || props.card.widgetID === 34)
            setIsNotPDF(true);
    }, [props.currentBreakpoint]);

    const handleExitFullscreen = () => document.exitFullscreen();

    const handleInfoClick = (cardId) => {      
        setInfoView(!isInfoView);  
        }

    const handleLicenseWarningClick = (cardId) => {
        setIsLicenseView(!isLicenseView);
    }

    const handleCardShare = () => {
        var getUrl = window.location;
        var urlValue = getUrl.protocol + "//" + getUrl.host + '/sc/?cID=' + props.card.cardURL + (isDemo === true ? '&d=1' : '');
        copy(urlValue);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    }


    const handleDownloadPDF = (cardID) => {         
       //const header = document.getElementById("cardName_"+props.card.cardID);
       const input = document.getElementById("cardDownload_" + props.card.cardID);                
       //const info = document.getElementById("Info_" + props.card.cardID);     
        html2canvas(input).then((canvas) => {
            var pdf;
            if (canvas.width > canvas.height) {
                pdf = new jsPDF('l', 'mm', [canvas.width / 3.5, canvas.height / 3]);
            }
            else {
                pdf = new jsPDF('p', 'mm', [canvas.height / 3.5, canvas.width / 3]);
            }            
            let widgetData = allWidgets.filter((e) => (e.cardWidgetID === props.card.widgetID));
            let cardSizeData = cardSize.filter((f) => (f.cardSizeID === widgetData[0].cardSizeID));           
            const w = cardSizeData[0].w;            
            const h = cardSizeData[0].h;                              
            pdf.addImage(canvas.toDataURL('image/png'), 'PNG', w, h);                   
            setInfoViewAvailable(true);
             for(let i=0; i<2;i++)
             {
                if(i==1){                
                setInfoView(!isInfoView);                                   
                savePDF(input, {
                    paperSize: "A4",
                    margin: 20,
                    fileName: props.card.name+"_Filter",
                    forcePageBreak: ".page-break",                     
                });
             }
                else{
                    pdf.addImage(canvas.toDataURL('image/png'), 'PNG', w, h);                   
                    pdf.save(props.card.name);
                    pdf.addPage();
             }             
        }
        setInfoView(isInfoView);         
    });               
    }

    const handleDownloadCSV = () => {
        let filterData = [];
        let organisationFilter = {};

        const iterableAppliedFilters = {};
        for (const key in appliedFilters) {
            if (appliedFilters.hasOwnProperty(key)) {
                const value = appliedFilters[key];
                iterableAppliedFilters[key] = Array.isArray(value) ? value : [value];
            }
        }

        console.log(Object.keys(iterableAppliedFilters).length); 

        for (const key in iterableAppliedFilters) {
            if (iterableAppliedFilters[key].isHtml) {
                organisationFilter.header = key;
                organisationFilter.value = iterableAppliedFilters[key].map(item => item.header).join(', ');
            }
        }

        filterData = Object.values(iterableAppliedFilters);
        filterData.push(organisationFilter);

        console.log(organisationFilter);
        console.log(iterableAppliedFilters);
        console.log(filterData);

        /*const urlCID = props.card.cardURL;
        FiltersApiService.GetCardCSVData(urlCID).then((response) => {
            if (response !== undefined && response !== "") {
                const XLSX = require('xlsx');
                let data = response;
                let cardWorkSheet = XLSX.utils.json_to_sheet(data);
                let cardWorkSheet1 = XLSX.utils.json_to_sheet(filterData);
                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, cardWorkSheet, "Card Data");
                XLSX.utils.book_append_sheet(wb, cardWorkSheet1, "Filter Data");
                XLSX.writeFile(wb, props.card.name + '.xlsx');
            }
        });*/

        const urlCID = props.card.cardURL;

        FiltersApiService.GetCardCSVData(urlCID)
            .then((response) => {
                if (response && response.length > 0) {
                    const XLSX = require('xlsx');
                    const data = response;//.map(item => ({
                    //     PropertyName: item.PropertyName,
                    //     PropertyValue: item.PropertyValue,
                    // }));
                    //console.log('Data types:', data.map(item => typeof item.PropertyName));

                    let cardWorkSheet = XLSX.utils.json_to_sheet(data);
                    let cardWorkSheet1 = XLSX.utils.json_to_sheet(filterData);

                    var wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, cardWorkSheet, 'Card Data');
                    XLSX.utils.book_append_sheet(wb, cardWorkSheet1, 'Filter Data');

                    XLSX.writeFile(wb, props.card.name + '.xlsx', {
                        bookType: 'xlsx',
                        mimeType: 'application/vnd.ms-excel',
                        bookSST: true,
                    });
                        // .then(() => {
                        //     console.log('File written successfully.');
                        // })
                        // .catch((error) => {
                        //     console.error('Error writing Excel file:', error);
                        // });
                } else {
                    console.error('Invalid or empty response data.');
                }
            })
            .catch((error) => {
                console.error('Error fetching or processing data:', error);
            });
    };



    const downloadItem = [
      {
        text: 'as .csv',
          onClick: () => handleDownloadCSV(),
        disabled: isDemo ? true : false
      }
      ,
      {
        text: 'as .pdf',       
         onClick: () => handleExportWithComponent(),
         disabled: isDemo ? true : false        
      }     
    ]
   
    const downloadItem1 = [
        {
            text: 'as .csv',
            onClick: () => handleDownloadCSV(),
            disabled: isDemo ? true : false
        }           
      ]
                                         
    const handleDownloadItemClick = (event) => {
       
        downloadItem.map((i) => (i.text === event.target.innerText ? i.onClick() : null))
    }


    const handleDownloadCSVCallBack = (cardDownload) => {
        setDownloadCardCSV(cardDownload);
    }
    
    const handleDelaySavePDF = () => {
        setTimeout(() => {
            setYesPDF(false);
        }, 1000); 
    }
    
    const handleExportWithComponent = () => {       
        const el = document.getElementById("cardDownload_" + props.card.cardID);          
        if (props.card.widgetID === 40 || props.card.widgetID === 35 || props.card.widgetID === 37 || props.card.widgetID === 45 || props.card.widgetID === 47 
            || props.card.widgetID === 48 || props.card.widgetID === 56 )
            {
                html2canvas(el).then((canvas) => {
                    var pdf;
                    if (canvas.width > canvas.height) {
                        pdf = new jsPDF('l', 'mm', [canvas.width / 3.5, canvas.height / 3]);
                    }
                    else {
                        pdf = new jsPDF('p', 'mm', [canvas.height / 3.5, canvas.width / 3]);
                    }            
                    let widgetData = allWidgets.filter((e) => (e.cardWidgetID === props.card.widgetID));
                    let cardSizeData = cardSize.filter((f) => (f.cardSizeID === widgetData[0].cardSizeID));           
                    const w = cardSizeData[0].w;            
                    const h = cardSizeData[0].h;                       
                    pdf.addImage(canvas.toDataURL('image/png'), 'PNG', w, h);       
                    setInfoViewAvailable(true);                    
                     for(let i=0; i<2;i++)
                     {
                        if(i==1){
                        setInfoView(!isInfoView);
                        savePDF(el, {
                            paperSize: "A1",
                            margin: 20,
                            fileName: props.card.name+"_Filter",
                            repeatHeaders: true,
                            forcePageBreak: ".page-break",  
                        });
                     }
                        else{
                           pdf.addImage(canvas.toDataURL('image/png'), 'PNG', w, h); 
                            pdf.save(props.card.name);
                            pdf.addPage();
                     }             
                }
                setInfoView(isInfoView); 
               });   
        }
        else if (props.card.widgetID === 49 || props.card.widgetID === 50 || props.card.widgetID === 51 || props.card.widgetID === 54)
        {
            html2canvas(el).then((canvas) => {
                var pdf;
                if (canvas.width > canvas.height) {
                    pdf = new jsPDF('l', 'mm', [canvas.width / 3.5, canvas.height / 3]);
                }
                else {
                    pdf = new jsPDF('p', 'mm', [canvas.height / 3.5, canvas.width / 3]);
                }            
                let widgetData = allWidgets.filter((e) => (e.cardWidgetID === props.card.widgetID));
                let cardSizeData = cardSize.filter((f) => (f.cardSizeID === widgetData[0].cardSizeID));           
                const w = cardSizeData[0].w;            
                const h = cardSizeData[0].h;                       
                pdf.addImage(canvas.toDataURL('image/png'), 'PNG', w, h);       
                setInfoViewAvailable(true); 
                 for(let i=0; i<2;i++)
                 {
                    if(i==1){
                    setInfoView(!isInfoView);
                     savePDF(el, {
                    paperSize: "A3",
                    margin: 20,
                    fileName: props.card.name,
                    repeatHeaders: true,
                    forcePageBreak: ".page-break",
                });
                 }
            else{
                pdf.addImage(canvas.toDataURL('image/png'), 'PNG', w, h); 
                pdf.save(props.card.name);
                pdf.addPage();
                 }             
            }
            setInfoView(isInfoView); 
           });   
        }
        else if (props.card.widgetID === 16 || props.card.widgetID === 4 || props.card.widgetID === 0 || props.card.widgetID === 2 || props.card.widgetID === 57
            || props.card.widgetID === 60 || props.card.widgetID === 46 || props.card.widgetID === 55 || props.card.widgetID === 3 || props.card.widgetID === 19){
            handleDownloadPDF(props.card.cardID);
            }
        else if (props.card.widgetID === 9)
        {                 
                  setInfoViewAvailable(true);                          
                
                 for(let i=0; i<2;i++)
                 {
                    if(i==1){
                    setInfoView(!isInfoView);
                    savePDF(el, {
                        title : props.card.name,
                        paperSize: "A7",
                        margin: 20,
                        fileName: props.card.name +"_Filter",
                    }); 
                 }                
                  else{
                        savePDF(el, {
                            title : props.card.name,
                            paperSize: "A7",
                            margin: 20,
                            fileName: props.card.name,
                        }); 
            }
        }
            setInfoView(isInfoView);          
    }        
        else{
            html2canvas(el).then((canvas) => {
                var pdf;
                if (canvas.width > canvas.height) {
                    pdf = new jsPDF('l', 'mm', [canvas.width / 3.5, canvas.height / 3]);
                }
                else {
                    pdf = new jsPDF('p', 'mm', [canvas.height / 3.5, canvas.width / 3]);
                }            
                let widgetData = allWidgets.filter((e) => (e.cardWidgetID === props.card.widgetID));
                let cardSizeData = cardSize.filter((f) => (f.cardSizeID === widgetData[0].cardSizeID));           
                const w = cardSizeData[0].w;            
                const h = cardSizeData[0].h;                       
                pdf.addImage(canvas.toDataURL('image/png'), 'PNG', w, h);       
                setInfoViewAvailable(true);
                 for(let i=0; i<2;i++)
                 {
                    if(i==1){
                    setInfoView(!isInfoView);
                    savePDF(el, {
                        paperSize: "auto",
                        margin: 20,
                        fileName: props.card.name,
                    });
                 }
                    else{
                       pdf.addImage(canvas.toDataURL('image/png'), 'PNG', w, h); 
                        pdf.save(props.card.name+"_Filter");
                        pdf.addPage();
                 }             
            }
            setInfoView(isInfoView); 
           });   
            handleDelaySavePDF()
        }
    };

    return (
        //<MaximizableView>
        <WindowContext>{
            (context) => (
        <SavePDFProvider value={yesPDF}>
        <div id ={"cardParent_"+props.card.cardID} style={{ width: '100%', height: '100%' }} ref={maximizableElement}>
            <Card compact fluid >
                <CardHeader id ={"cardName_"+props.card.cardID}>
                    <Flex 
                        vAlign="center"
                        className="cardDesc"
                        space="between"
                        style={{ width: "100%" }}
                    >
                        <Tooltip className="tooltipTxt"                        
                            trigger={
                                <Text content={props.card.name} weight="bold" truncated />}                                
                            align="center"
                            position="below"
                            content={props.card.name}
                        />
                        <Flex style={{ marginRight: "2px" }} className="cardActions">
                            {/* <Dialog
                                className="shareDialog"
                                closeOnOutsideClick={true}
                                cancelButton="Close"
                                trigger={<Button disabled={operating} icon={<DownloadIcon />} iconOnly text title="Download" />}
                                content={<Text size="large" content="Coming Soon: You will be able to download the report locally." />}
                            /> */}
                            {
                                isLicenseWarningAvailable && !isDemo &&
                                <Button
                                    className={isLicenseView ? "" : "nonprimary"}
                                    disabled={operating || props.disabled}
                                    icon={isLicenseView ? <LeaveIcon /> : <ExclamationTriangleIcon styles={{fill: 'rgb(248, 210, 42) !important', color: 'rgb(248, 210, 42) !important'}} />}
                                    iconOnly
                                    text
                                    title={isLicenseView ? "Data View" : "Licence Warning"}
                                    onClick={() => handleLicenseWarningClick(props.card.cardID)}
                                />
                            }
                            {
                                isInfoViewAvailable && 
                                <Button
                                    disabled={operating || props.disabled}
                                    icon={isInfoView ? <TranscriptIcon /> :<InfoIcon />}
                                    iconOnly
                                    text
                                    title={isInfoView ? "Data View" : "Info View"}
                                    onClick={() => handleInfoClick(props.card.cardID)}
                                    style={{ whiteSpace: 'break-spaces' }}

                                />
                            }
                            <Button  
                                disabled={operating || props.disabled}
                                icon={copied ? <ApprovalsAppbarIcon /> : <LinkIcon />}
                                iconOnly
                                text
                                title="Copy Link"
                                onClick={() => handleCardShare()}
                            />
                                {isLoaded &&
                                <MenuButton   

                                trigger={<Button 
                                            icon={<DownloadIcon />}
                                            disabled={operating || props.disabled}
                                            text 
                                            title="Download" 
                                            iconOnly
                                            onClick={()=>setYesPDF(true)}
                                            
                                        />}
                                menu={isNotPDF ? downloadItem1.map((element) => element.text) : downloadItem.map((element) => element.text)}
                                on="click"
                                className={isDemo ? "demoClickDisabled" : ""}
                                onMenuItemClick={(event) => handleDownloadItemClick(event)}
                                onMouseEnter={()=>setYesPDF(true)}
                                onMouseLeave={()=>handleDelaySavePDF()}
                                
                                />
                            } 
                            <Button
                                disabled={operating || props.disabled}
                                icon={<EditIcon />}
                                id={props.card.name === "Meeting Habits" || props.card.name === "Direct Report Overview" || props.card.name === "Call Queue Summary" ? "editId" : "editId"}
                                iconOnly
                                text
                                title="Edit"
                                onClick={() => props.onCardEdit(props.card.cardID)}
                            />
                            <Button
                                disabled={operating || props.disabled}
                                icon={<TrashCanIcon />}
                                iconOnly
                                text
                                title="Remove"
                                onClick={() => {
                                setOperating(true);
                                props.onCardRemove(props.card.cardID);
                                }}
                            />
                            { !context.mediaCategory.sm && (errorMessage ? <></> :
                                // (props.currentBreakpoint == "lg" || props.currentBreakpoint == "md") ?
                                isFullscreen ? (
                                    <Button
                                    icon={<CollapseIcon />}
                                    iconOnly
                                    text
                                    title="Exit Fullscreen"
                                    onClick={handleExitFullscreen}
                                    />
                                ) : (
                                    <Button
                                    icon={<ExpandIcon />}
                                    iconOnly
                                    text
                                    title="Fullscreen"
                                    onClick={setIsFullscreen}
                                    />
                                ))
                                //: <></>
                            }
                        </Flex>
                    </Flex>
                </CardHeader>
                <CardBody id={"cardDownload_" + props.card.cardID}>
                    {!isLicenseView ?
                        (!isInfoView ?
                            isDemo ? ( 
                                {
                                    0: <StandardGrid data={mockData} widgetId={props.card.widgetID} downloadCard={downloadCardCSV} valueAfterCardDownload={handleDownloadCSVCallBack} />,
                                    2: <GroupedGrid data={mockData} widgetId={props.card.widgetID} downloadCard={downloadCardCSV} valueAfterCardDownload={handleDownloadCSVCallBack} />,
                                    3: <MeetingSummaryView data={mockData} />,
                                    4: <MeetingHabitsIndicator data={mockData} />,
                                    5: <DonutChart data={mockData} />,
                                    6: <ReactionsView data={mockData} />,
                                    7: <BarChart data={mockData} widgetId={props.card.widgetID} />,
                                    8: <DonutChart data={mockData} />,
                                    9: <TopUsersGrid data={mockData} widgetId={props.card.widgetID} />,
                                    10: <TeamSummaryView data={mockData} />,
                                    11: <MeetingParticipation data={mockData} />,
                                    12: <BasicSummary data={mockData} />,
                                    13: <UserSummaryView data={mockData} downloadCard={downloadCardCSV} valueAfterCardDownload={handleDownloadCSVCallBack}/>,
                                    15: <UserComparisonView data={mockData} />,
                                    16: <ComparisonView data={mockData} />,
                                    18: <StackedBarChart data={mockData} widgetId={props.card.widgetID} />,
                                    19: <PieChart data={mockData} />,
                                    20: <CallQueueSummaryView data={mockData} />,
                                    21: <StackedBarChartAndGroupedGrid data={mockData} widgetId={props.card.widgetID} downloadCard={downloadCardCSV} valueAfterCardDownload={handleDownloadCSVCallBack} />,
                                    22: <TeamEngagementView data={mockData} widgetId={props.card.widgetID} />,
                                    23: <ExpandableRowGrid data={mockData} widgetId={props.card.widgetID} downloadCard={downloadCardCSV} valueAfterCardDownload={handleDownloadCSVCallBack} />,
                                    24: <OnetoOneOverview data={mockData} widgetId={props.card.widgetID} />,
                                    25: <ManagementInsights data={mockData} />,
                                    26: <ColumnChart data={mockData} widgetId={props.card.widgetID} />,
                                    27: <DirectReportOverview data={mockData} widgetId={props.card.widgetID} />,
                                    28: <OrganizationalChart data={mockData} widgetId={props.card.widgetID} />,
                                    29: <GridFilteredByInsight data={mockData} widgetId={props.card.widgetID} downloadCard={downloadCardCSV} valueAfterCardDownload={handleDownloadCSVCallBack} />,
                                    30: <FreemiumCardView />,
                                    31:<LineChart data={data.data} />                                   

                                // 20: <UserAnalytics data={data} />,
                                }[props.card.viewTypeID]
                            ) : isLoaded ? (
                                !noData ? (
                                    {
                                        0: <StandardGrid data={data.data} widgetId={props.card.widgetID} downloadCard={downloadCardCSV} valueAfterCardDownload={handleDownloadCSVCallBack} />,
                                        2: <GroupedGrid data={data.data} widgetId={props.card.widgetID} downloadCard={downloadCardCSV} valueAfterCardDownload={handleDownloadCSVCallBack} />,
                                        3: <MeetingSummaryView data={data.data} />,
                                        4: <MeetingHabitsIndicator data={data.data} />,
                                        5: <DonutChart data={data.data} />,
                                        6: <ReactionsView data={data.data} />,
                                        7: <BarChart data={data.data} widgetId={props.card.widgetID}/>,
                                        8: <DonutChart data={data.data} />,
                                        9: <TopUsersGrid data={data.data} widgetId={props.card.widgetID} />,
                                        10: <TeamSummaryView data={data.data} />,
                                        11: <MeetingParticipation data={data.data} />,
                                        12: <BasicSummary data={data.data} />,
                                        13: <UserSummaryView data={data.data} downloadCard={downloadCardCSV} valueAfterCardDownload={handleDownloadCSVCallBack}/>,
                                        15: <UserComparisonView data={data.data} />,
                                        16: <ComparisonView data={data.data} />,
                                        18: <StackedBarChart data={data.data} widgetId={props.card.widgetID}/>,
                                        19: <PieChart data={data.data} />,
                                        20: <CallQueueSummaryView data={data.data} />,
                                        21: <StackedBarChartAndGroupedGrid data={data.data} widgetId={props.card.widgetID} downloadCard={downloadCardCSV} valueAfterCardDownload={handleDownloadCSVCallBack} />,
                                        22: <TeamEngagementView data={data.data} widgetId={props.card.widgetID} />,
                                        23: <ExpandableRowGrid data={data.data} widgetId={props.card.widgetID} downloadCard={downloadCardCSV} valueAfterCardDownload={handleDownloadCSVCallBack} />,
                                        24: <OnetoOneOverview data={data.data} widgetId={props.card.widgetID} />,
                                        25: <ManagementInsights data={data.data} />,
                                        26: <ColumnChart data={data.data} widgetId={props.card.widgetID} />,
                                        27: <DirectReportOverview data={data.data} widgetId={props.card.widgetID} />,
                                        28: <OrganizationalChart data={data.data} widgetId={props.card.widgetID} />,
                                        29: <GridFilteredByInsight data={data.data} widgetId={props.card.widgetID} downloadCard={downloadCardCSV} valueAfterCardDownload={handleDownloadCSVCallBack} filters = {appliedFilters} />,
                                        30: <FreemiumCardView />,
                                        31:<LineChart data={data.data} />

                                        // 20: <UserAnalytics data={data.data} />,
                                    }[props.card.viewTypeID]
                                ) : (
                                    <Flex vAlign="center" fill hAlign="center">
                                        <Text size="large" content="No data to display." />
                                    </Flex>
                                )
                            ) : (
                                <Loader style={{ height: "100%" }} />
                            )
                            :                           
                            <div id={"Info_"+ props.card.cardID} style={{ padding: '8px' }}>
                                <Text style={{whiteSpace: "break-spaces"}} content={MockData[props.card.widgetID].description} />
                                <div style={{marginTop: '1rem', marginBottom: '.5rem' }}><Text content="Applied Filters :" weight="semibold"/></div>
                                <div className="card-filters" style={{ gridTemplateColumns: `repeat(${cardLayout.w}, 1fr)` }}>
                                    {
                                        appliedFiltersForInfo.map((d) => {
                                            return (
                                                <div className="card-filter-row">
                                                    <div className="card-filter-header">
                                                        <Text weight="bold" content={d.header} />
                                                    </div>
                                                    <div className="py-1">
                                                        {
                                                            d.isHtml ?
                                                                d.value.map((dd) => {
                                                                    return (
                                                                        <Avatar
                                                                            image={dd.additionalContent}
                                                                            label={dd.value === -10 ? dd.header : ""}
                                                                            name={dd.value !== -10 ? dd.header : ""}
                                                                            size="small"
                                                                            className="mr-1"
                                                                        />
                                                                    );
                                                                })
                                                                :
                                                                <Text className="card-filter-value" content={d.value} />       
                                                          }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>)                                         
                        :
                        <div style={{ padding: '8px' }}>
                            <div style={{ paddingLeft: '12px', marginTop: '8px', marginBottom: '4px' }}><Text content="Based on the selected filters, the following users do not have the correct licences :" /></div>
                            <div className="card-filters" style={{ gridTemplateColumns: `repeat(${cardLayout.w}, 1fr)` }}>
                                {
                                    invalidLicenseUsers.map((d) => {
                                        return (
                                            <div className="card-invalidLicence-row">
                                                <div>
                                                <Avatar
                                                    image={d.userPhoto}
                                                    name={d.userName}
                                                />
                                                </div>
                                                <div className="card-filter-header">
                                                    <Text weight="bold" content={d.userName} truncated/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                </CardBody>
            </Card>
        </div>
        </SavePDFProvider>
        )}</WindowContext>
    //</MaximizableView>
    );
};

export { CardWidget, WidgetPreview, CardLivePreview };

//--------------------Data of handleCSVdata function------------------
// if (props.card.viewTypeID === 0 || props.card.viewTypeID === 2) {
//     setDownloadCardCSV(true);
// }// else {
//     if (props.card.viewTypeID === 3)
//       MeetingSummaryDownload();
//     else if (props.card.viewTypeID === 4)
//       MeetingHabitDownload();
//     else if (props.card.viewTypeID === 5)
//       DonutDownlaod();
//     else if (props.card.viewTypeID === 6)
//       ReactionsDownload();
//     else if (props.card.viewTypeID === 7 || props.card.viewTypeID === 18)
//       BarChartDownload();
//     else if (props.card.viewTypeID === 10)
//       TeamOverviewDownload();
//     else if (props.card.viewTypeID === 11)
//       MeetingParticipationDownload();
//     else if (props.card.viewTypeID === 13)
//       PeopleSummaryDownload();
//     else if (props.card.viewTypeID === 15)
//       PeopleComparisonView();
//     else if (props.card.viewTypeID === 16)
//       TeamComparisonView();
//     else if (props.card.viewTypeID === 20)
//       CallQueueSummaryViewDownload();
//     else if (props.card.viewTypeID === 21)
//       StackedBarChartAndGroupedGridDownload();
//     else if (props.card.viewTypeID === 22)
//       TeamEngagementDownload();
//     else if (props.card.viewTypeID === 23)
//       ExpandableRowGridDownload();
//     else if (props.card.viewTypeID === 24)
//       OnetoOneOverviewDownload();
//     else if (props.card.viewTypeID === 25)
//       ManagementInsightsDownload();
//     else if (props.card.viewTypeID === 26)
//       OnetoOneTrendsDownload();
//     else if (props.card.viewTypeID === 27)
//       DirectReportOverviewDownload();
//     else if (props.card.viewTypeID === 28)
//       OrganizationalChartDownload();
// }
//----------------------------------------------------------------

// const TeamComparisonView = () => 
//     {
//       const downloadData = [];
//       var displayName = {};
//       displayName.name = "Team Name";
//       displayName.team1 = data.data.displayName1;
//       displayName.team2 = data.data.displayName2;
//       downloadData.push(displayName);
//       var timeInMeeting = {}
//       timeInMeeting.name = "Time in meetings";
//       timeInMeeting.team1 = data.data.timeInMeeting1;
//       timeInMeeting.team2 = data.data.timeInMeeting2;
//       downloadData.push(timeInMeeting);
//       var teamMeetings = {};
//       teamMeetings.name = "Team Meetings";
//       teamMeetings.team1 = data.data.countMeeting1;
//       teamMeetings.team2 = data.data.countMeeting2;
//       downloadData.push(teamMeetings);
//       var recurring = {};
//       recurring.name = "Recurring";
//       recurring.team1 = data.data.recurrence1;
//       recurring.team2 = data.data.recurrence2;
//       downloadData.push(recurring);
//       var posts = {};
//       posts.name = "Post";
//       posts.team1 = data.data.sent1;
//       posts.team2 = data.data.sent2;
//       downloadData.push(posts);
//       var replies = {};
//       replies.name = "Replies";
//       replies.team1 = data.data.replied1;
//       replies.team2 = data.data.replied2;
//       downloadData.push(replies);
//       var mentions = {};
//       mentions.name = "Mentions";
//       mentions.team1 = data.data.mentionedCount1;
//       mentions.team2 = data.data.mentionedCount2;
//       downloadData.push(mentions);
//       var reaction = {};
//       reaction.name = "Reaction";
//       reaction.team1 = data.data.reactionCount1;
//       reaction.team2 = data.data.reactionCount2;
//       downloadData.push(reaction);
//       var sentiment = {};
//       sentiment.name = "Sentiment";
//       sentiment.team1 = data.data.sentiment1;
//       sentiment.team2 = data.data.sentiment1;
//       downloadData.push(sentiment);
//       var external = {};
//       external.name = "External";
//       external.team1 = data.data.external1;
//       external.team2 = data.data.external2;
//       downloadData.push(external);
//       var ooh = {};
//       ooh.name = "OOH";
//       ooh.team1 = data.data.outOfHours1;
//       ooh.team2 = data.data.outOfHours2;
//       downloadData.push(ooh);
//       var screenShare = {};
//       screenShare.name = "Screen Share";
//       screenShare.team1 = data.data.screenSharing1;
//       screenShare.team2 = data.data.screenSharing2;
//       downloadData.push(screenShare);
//       var totalUsers = {};
//       totalUsers.name = "Total Users";
//       totalUsers.team1 = data.data.teamSummary1.totalUsers;
//       totalUsers.team2 = data.data.teamSummary2.totalUsers;
//       downloadData.push(totalUsers);
//       var totalUsers = {};
//       totalUsers.name = "Total Users";
//       totalUsers.team1 = data.data.teamSummary1.totalUsers;
//       totalUsers.team2 = data.data.teamSummary2.totalUsers;
//       downloadData.push(totalUsers);
//       var activeUser = {};
//       activeUser.name = "Active User";
//       activeUser.team1 = data.data.teamSummary1.activeUsers;
//       activeUser.team2 = data.data.teamSummary2.activeUsers;
//       downloadData.push(activeUser);
//       var activeUser = {};
//       activeUser.name = "Active User";
//       activeUser.team1 = data.data.teamSummary1.activeUsers;
//       activeUser.team2 = data.data.teamSummary2.activeUsers;
//       downloadData.push(activeUser);
//       var members = {};
//       members.name = "Members";
//       members.team1 = data.data.teamSummary1.memberGuests;
//       members.team2 = data.data.teamSummary2.memberGuests;
//       downloadData.push(members);
//       var owners = {};
//       owners.name = "Owners";
//       owners.team1 = data.data.teamSummary1.owners;
//       owners.team2 = data.data.teamSummary2.owners;
//       downloadData.push(owners);
//       var latestActivityTime = {};
//       latestActivityTime.name = "Latest Activity";
//       latestActivityTime.team1 = data.data.teamSummary1.latestActivityTime;
//       latestActivityTime.team2 = data.data.teamSummary2.latestActivityTime;
//       downloadData.push(latestActivityTime);

//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');

//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);

//     }

//     const MeetingSummaryDownload = () => 
//     {
//       const DownloadData = [];
//       var organised = {};
//       organised.name="Organised";
//       organised.total = data.data.totalOrganized;
//       organised.internal = data.data.internalOrganized;
//       organised.esternal = data.data.externalOrganized;
//       DownloadData.push(organised);
//       var participated = {};
//       participated.name="Participated";
//       participated.total = data.data.totalParticipated;
//       participated.internal = data.data.internalParticipated;
//       participated.esternal = data.data.externalParticipation;
//       DownloadData.push(participated);
//       var timeInMeetings = {};
//       timeInMeetings.name="Time In Meetings";
//       timeInMeetings.total = data.data.totalTimeInMeetings;
//       timeInMeetings.internal = data.data.internalTimeInMeetings;
//       timeInMeetings.esternal = data.data.externalTimeInMeetings;
//       DownloadData.push(timeInMeetings);
//       var externalParticipation = {};
//       externalParticipation.name="External participation %";
//       externalParticipation.total = data.data.externalParticipation;
//       DownloadData.push(externalParticipation);
//       var timeSpentOnMeeting = {};
//       timeSpentOnMeeting.name="Time spent on meeting %";
//       timeSpentOnMeeting.total = data.data.timeSpentOnMeeting;
//       DownloadData.push(timeSpentOnMeeting);
//       var attended = {};
//       attended.name="Attended %";
//       attended.total = data.data.attended;
//       DownloadData.push(attended);
//       var overRunMeetings = {};
//       overRunMeetings.name="Over run Meetings %";
//       overRunMeetings.total = data.data.overrun;
//       DownloadData.push(overRunMeetings);

//       var items = DownloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');

//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);

//     }

//     const MeetingHabitDownload = () => 
//     {
//       const downloadData = [];
//       var invitations = {};
//       invitations.name = "Invitations sent within 24 hours of meeting %";
//       invitations.value = data.data.atLeastTwentyFourHoursNotice;
//       downloadData.push(invitations);
//       var lowAttendance = {};
//       lowAttendance.name = "Low attendance %";
//       lowAttendance.value = data.data.attendance;
//       downloadData.push(lowAttendance);
//       var noMessages = {};
//       noMessages.name = "Chat to people outside of the meeting %";
//       noMessages.value = data.data.noMessageToPeopleOutsideMeeting;
//       downloadData.push(noMessages);
//       var joinedOnTime = {};
//       joinedOnTime.name = "Online meetings joined on time %";
//       joinedOnTime.value = data.data.joinedBeforeMeetingStarts;
//       downloadData.push(joinedOnTime);
//       var MeetingOutsideOfWorkinghHours = {};
//       MeetingOutsideOfWorkinghHours.name = "Meeting outside of working hours %";
//       MeetingOutsideOfWorkinghHours.value = data.data.outsideWorkingHours;
//       downloadData.push(MeetingOutsideOfWorkinghHours);
      
//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');

//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }

//     const DonutDownlaod = () => 
//     {
//       const downloadData = [];
//       for(var i =0; i<data.data.length; i++)
//       {
//         var donutvalues = {};
//         donutvalues.name = data.data[i].name + " %";
//         donutvalues.value = data.data[i].share;
//         downloadData.push(donutvalues);
//       }

//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');
//       csv = csv.substr(12);

//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }

//     const ReactionsDownload = () => 
//     {
//       const downloadData = [];
//       for(var i =0; i<data.data.length; i++)
//       {
//         var donutvalues = {};
//         donutvalues.name = data.data[i].reactionName;
//         donutvalues.count = data.data[i].reactionCount;
//         downloadData.push(donutvalues);
//       }

//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');
//       csv = csv.substr(33);

//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }
    
//     const BarChartDownload = () => 
//     {
//       const downloadData = [];
//       // var values = {};
//       // values.name = data.data.series[0].name; 
//       // this["values.variable" + i] = data.data.categories[i];
      
//       // for(let i=0; i<data.data.categories.length; i++)
//       // {
//       //   var values = {};
//       //   values.name = data.data.series[0].name;
//       //   this["values.variable" + i] = data.data.categories[i];
//       // }
    
//       if(data.data.series.length === 1)
//       {
//         var excelHeader = {};
//         excelHeader.name = "";
//         excelHeader.variable1 = data.data.series[0].name;
//         downloadData.push(excelHeader);
//         for (var i = 0; i < data.data.categories.length; i++)
//         {
//           var values = {};
//           values.name = data.data.categories[i];
//           values.variable1 = data.data.series[0].data[i];
//           downloadData.push(values);
//         }
//       }
//       else if(data.data.series.length === 2)
//       {
//         var excelHeader = {};
//         excelHeader.name = "";
//         excelHeader.variable1 = data.data.series[0].name;
//         excelHeader.variable2 = data.data.series[1].name;
//         downloadData.push(excelHeader);
//         for (var i = 0; i < data.data.categories.length; i++)
//         {
//           var values = {};
//           values.name = data.data.categories[i];
//           values.variable1 = data.data.series[0].data[i];
//           values.variable2 = data.data.series[1].data[i];
//           downloadData.push(values);
//         }
//       }
//       else if(data.data.series.length === 3)
//       {
//         var excelHeader = {};
//         excelHeader.name = "";
//         excelHeader.variable1 = data.data.series[0].name;
//         excelHeader.variable2 = data.data.series[1].name;
//         excelHeader.variable3 = data.data.series[2].name;
//         downloadData.push(excelHeader);
//         console.log(downloadData);
//         for (var i = 0; i < data.data.categories.length; i++)
//         {
//           var values = {};
//           values.name = data.data.categories[i];
//           values.variable1 = data.data.series[0].data[i];
//           values.variable2 = data.data.series[1].data[i];
//           values.variable3 = data.data.series[2].data[i];
//           downloadData.push(values);
//         }
//       }
//       else if(data.data.series.length === 4)
//       {
//         var excelHeader = {};
//         excelHeader.name = "";
//         excelHeader.variable1 = data.data.series[0].name;
//         excelHeader.variable2 = data.data.series[1].name;
//         excelHeader.variable3 = data.data.series[2].name;
//         excelHeader.variable4 = data.data.series[3].name;
//         downloadData.push(excelHeader);
//         console.log(downloadData);
//         for (var i = 0; i < data.data.categories.length; i++)
//         {
//           var values = {};
//           values.name = data.data.categories[i];
//           values.variable1 = data.data.series[0].data[i];
//           values.variable2 = data.data.series[1].data[i];
//           values.variable3 = data.data.series[2].data[i];
//           values.variable4 = data.data.series[3].data[i];
//           downloadData.push(values);
//         }
//       }
//       else if(data.data.series.length === 5)
//       {
//         var excelHeader = {};
//         excelHeader.name = "";
//         excelHeader.variable1 = data.data.series[0].name;
//         excelHeader.variable2 = data.data.series[1].name;
//         excelHeader.variable3 = data.data.series[2].name;
//         excelHeader.variable4 = data.data.series[3].name;
//         excelHeader.variable5 = data.data.series[4].name;
//         downloadData.push(excelHeader);
//         console.log(downloadData);
//         for (var i = 0; i < data.data.categories.length; i++)
//         {
//           var values = {};
//           values.name = data.data.categories[i];
//           values.variable1 = data.data.series[0].data[i];
//           values.variable2 = data.data.series[1].data[i];
//           values.variable3 = data.data.series[2].data[i];
//           values.variable4 = data.data.series[3].data[i];
//           values.variable5 = data.data.series[4].data[i];
//           downloadData.push(values);
//         }
//       }

//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');
      
//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }

//     const TeamOverviewDownload = () => 
//     {
//       const downloadData = [];
//       var totalChannels = {}
//       totalChannels.name = "Total Channels";
//       totalChannels.value = data.data.totalChannels;
//       downloadData.push(totalChannels);
//       var totalUsers = {}
//       totalUsers.name = "Total Users";
//       totalUsers.value = data.data.totalUsers;
//       downloadData.push(totalUsers);
//       var members = {}
//       members.name = "Members";
//       members.value = data.data.memberGuests;
//       downloadData.push(members);
//       var owners = {}
//       owners.name = "Owners";
//       owners.value = data.data.owners;
//       downloadData.push(owners);
//       var activeUsers = {}
//       activeUsers.name = "Active Users";
//       activeUsers.value = data.data.activeUsers;
//       downloadData.push(activeUsers);
//       var totalMeetings = {}
//       totalMeetings.name = "Total Calls";
//       totalMeetings.value = data.data.totalMeetings;
//       downloadData.push(totalMeetings);
//       var totalMessages = {}
//       totalMessages.name = "Total Messages";
//       totalMessages.value = data.data.totalMessages;
//       downloadData.push(totalMessages);
//       var latestActivityTime = {}
//       latestActivityTime.name = "Last Activity Time";
//       latestActivityTime.value = data.data.latestActivityTime;
//       downloadData.push(latestActivityTime);
//       var latestActivitySubject = {}
//       latestActivitySubject.name = "Last Activity Subject";
//       latestActivitySubject.value = data.data.latestActivitySubject;
//       downloadData.push(latestActivitySubject);
//       var nextMeetingSubject = {}
//       nextMeetingSubject.name = "Next Meeting Subject";
//       nextMeetingSubject.value = data.data.nextMeetingSubject;
//       downloadData.push(nextMeetingSubject);
//       var nextMeetingTime = {}
//       nextMeetingTime.name = "Next Meeting Time";
//       nextMeetingTime.value = data.data.nextMeetingTime;
//       downloadData.push(nextMeetingTime);
//       var nextMeetingOrganiser = {}
//       nextMeetingOrganiser.name = "Next Meeting Organiser";
//       nextMeetingOrganiser.value = data.data.nextMeetingOrganiser;
//       downloadData.push(nextMeetingOrganiser);

//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');
      
//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }

//     const MeetingParticipationDownload = () => 
//     {
//       const downloadData = [];
//       var attendance = {};
//       attendance.name = "Attendance at Scheduled Meetings %";
//       attendance.value = data.data;
//       downloadData.push(attendance);

//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');
      
//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }

//     const PeopleSummaryDownload = () => 
//     {
//       const downloadData = [];
//       var username = {};
//       username.name = "Name";
//       username.value = data.data.userName;
//       downloadData.push(username);
//       var email = {};
//       email.name = "Email";
//       email.value = data.data.email;
//       downloadData.push(email);
//       var designation = {};
//       designation.name = "Designation";
//       designation.value = data.data.designation;
//       downloadData.push(designation);
//       var calls = {};
//       calls.name = "Calls";
//       calls.value = "Total: " + data.data.calls.total + ", Total talk time: " + data.data.totalTalkTime + ", External %: " + data.data.externalPercent + ", One to One: " + data.data.peerCalls +
//                     ", Audio: " + data.data.calls.audio + ", Audio talk time: " + data.data.calls.totalAudioTalkTime + 
//                     ", Video: " + data.data.calls.video + ", Video talk time: " + data.data.calls.totalVideoTalkTime + ", Audio talk time: " + data.data.calls.totalAudioTalkTime;
//       downloadData.push(calls);
//       var messages = {};
//       messages.name = "Messages";
//       messages.value = "Total: " + data.data.messages.total + ", External %: " + data.data.messages.externalMessages + ", Group: " + data.data.messages.group + 
//                        ", One to One: " + data.data.messages.peer + ", Replied: " + data.data.messages.replied + ", Mentions: " + data.data.messages.mentions + 
//                        ", Post: " + data.data.messages.sent;
//       downloadData.push(messages);
//       var meetings = {};
//       meetings.name = "Meetings";
//       meetings.value = "Total: " + data.data.meetingOverview.totalMeetings + ", Attended: " + data.data.meetingOverview.totalAttended + ", Not Attended: " + data.data.meetingOverview.totalNotAttended + ", Statue Unknown: " + data.data.meetingOverview.totalStatusUnknown +
//                        ", Time in meetings: " + data.data.meetings.totalTimeInMeeting + ", Recurring: " + data.data.meetings.totalTimeMeetingRecurring + ", Time in recurring meetings: " + data.data.meetings.totalTimeMeetingRecurring + ", Back to Back Meetings: " + data.data.meetingOverview.totalBackToBackMeetings +
//                        ", Overlapped Meetings: " + data.data.meetings.totalOverlappingMeetings + ", Late Attendance: " + data.data.meetings.totalMeetingsLate + ", Overran meetings: " + data.data.meetings.totalMeetingsOverran;
//       downloadData.push(meetings);
//       var CollaborationMsg = {};
//       CollaborationMsg.name = "Collaborators Messages";
//       for (let i=0; i<data.data.collaborators.msgCollabList.length; i++)
//       {
//         if(i===0)
//           CollaborationMsg.value = data.data.collaborators.msgCollabList[i].topCollabName + ": " + data.data.collaborators.msgCollabList[i].topCollabCount;
//         else
//           CollaborationMsg.value = CollaborationMsg.value + ", " + data.data.collaborators.msgCollabList[i].topCollabName + ": " + data.data.collaborators.msgCollabList[i].topCollabCount;
//       }
//       downloadData.push(CollaborationMsg);
//       var CollaborationCalls = {};
//       CollaborationCalls.name = "Collaborators Calls";
//       for (let i=0; i<data.data.collaborators.collabList.length; i++)
//       {
//         if(i===0)
//           CollaborationCalls.value = data.data.collaborators.collabList[i].userName + ": " + data.data.collaborators.collabList[i].totalTalkTime;
//         else
//           CollaborationCalls.value = CollaborationCalls.value + ", " + data.data.collaborators.collabList[i].userName + ": " + data.data.collaborators.collabList[i].totalTalkTime;
//       }
//       downloadData.push(CollaborationCalls);
//       var meetingsXDays = {};
//       meetingsXDays.name = data.data.timePeriod;
//       meetingsXDays.value = "Total Meetings: " + data.data.nextXTime.totalMeetings + ", Duration: " + data.data.nextXTime.totalAttended + 
//                             ", Recurring: " + data.data.nextXTime.totalRecurringMeetings + ", Recurring Duration: " + data.data.nextXTime.totalTimeMeetingRecurring + 
//                             ", Overlapping: " + data.data.nextXTime.totalOverlappingMeetings + ", Overlapping Duration: " + data.data.nextXTime.totalTimeMeetingOverlapping;
//       downloadData.push(meetingsXDays);
//       var callQuality = {};
//       callQuality.name = "Call Quality";
//       callQuality.value = "Good: " + data.data.callQuality.good + ", Average: " + data.data.callQuality.avg + ", Poor: " + data.data.callQuality.poor ;
//       downloadData.push(callQuality);
//       var callQuality = {};
//       callQuality.name = "Call Quality";
//       callQuality.value = "Good: " + data.data.callQuality.good + ", Average: " + data.data.callQuality.avg + ", Poor: " + data.data.callQuality.poor ;
//       downloadData.push(callQuality);
//       var sentiment = {};
//       sentiment.name = "Sentiment";
//       sentiment.value = data.data.messages.avgSentiments;
//       downloadData.push(sentiment);

//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');
      
//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }

//     const PeopleComparisonView = () => 
//     {
//       const downloadData = [];
//       var displayName = {};
//       displayName.name = "User Name";
//       displayName.people1 = data.data.displayName1;
//       displayName.people2 = data.data.displayName2;
//       downloadData.push(displayName);
//       var callsOrganized = {}
//       callsOrganized.name = "Meeting Organized";
//       callsOrganized.people1 = data.data.callsOrganized1;
//       callsOrganized.people2 = data.data.callsOrganized2;
//       downloadData.push(callsOrganized);
//       var reactionCount = {};
//       reactionCount.name = "Reaction";
//       reactionCount.people1 = data.data.reactionCount1;
//       reactionCount.people2 = data.data.reactionCount2;
//       downloadData.push(reactionCount);
//       var mentionedCount = {};
//       mentionedCount.name = "Mentions";
//       mentionedCount.people1 = data.data.mentionedCount1;
//       mentionedCount.people2 = data.data.mentionedCount2;
//       downloadData.push(mentionedCount);
//       var sent = {};
//       sent.name = "Messages Sent";
//       sent.people1 = data.data.sent1;
//       sent.people2 = data.data.sent2;
//       downloadData.push(sent);
//       var replies = {};
//       replies.name = "Replies";
//       replies.people1 = data.data.replied1;
//       replies.people2 = data.data.replied2;
//       downloadData.push(replies);
//       var atLeastDaysNotice = {};
//       atLeastDaysNotice.name = "Invitations sent with at least a day's notice (%)";
//       atLeastDaysNotice.people1 = data.data.atLeastDaysNotice1;
//       atLeastDaysNotice.people2 = data.data.atLeastDaysNotice2;
//       downloadData.push(atLeastDaysNotice);
//       var attendance = {};
//       attendance.name = "High attendance (%)";
//       attendance.people1 = data.data.attendance1;
//       attendance.people2 = data.data.attendance2;
//       downloadData.push(attendance);
//       var noMessage = {};
//       noMessage.name = "No chat during meeting (%)";
//       noMessage.people1 = data.data.noMessage1;
//       noMessage.people2 = data.data.noMessage2;
//       downloadData.push(noMessage);
//       var joinedBeforeMeetingStarts = {};
//       joinedBeforeMeetingStarts.name = "Online meeting started on time (%)";
//       joinedBeforeMeetingStarts.people1 = data.data.joinedBeforeMeetingStarts1;
//       joinedBeforeMeetingStarts.people2 = data.data.joinedBeforeMeetingStarts2;
//       downloadData.push(joinedBeforeMeetingStarts);
//       var duringWorkingHours = {};
//       duringWorkingHours.name = "During working hours (%)";
//       duringWorkingHours.people1 = data.data.duringWorkingHours1;
//       duringWorkingHours.people2 = data.data.duringWorkingHours2;
//       downloadData.push(duringWorkingHours);
//       var noOverlap = {};
//       noOverlap.name = "No overlap with other meetings (%)";
//       noOverlap.people1 = data.data.noOverlap1;
//       noOverlap.people2 = data.data.noOverlap2;
//       downloadData.push(noOverlap);

//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');

//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }

//     const CallQueueSummaryViewDownload = () => 
//     {
//       const downloadData= [];
//       var callQueueName = {};
//       callQueueName.name = "Call Queue Name";
//       callQueueName.value = data.data.callQueueName;
//       downloadData.push(callQueueName);
//       var totalCalls = {};
//       totalCalls.name = "Total Calls";
//       totalCalls.value = data.data.totalCalls;
//       downloadData.push(totalCalls);
//       var answeredCalls = {};
//       answeredCalls.name = "Answered Calls";
//       answeredCalls.value = data.data.answeredCalls;
//       downloadData.push(answeredCalls);
//       var missedCalls = {};
//       missedCalls.name = "Missed Calls";
//       missedCalls.value = data.data.missedCalls;
//       downloadData.push(missedCalls);
//       var returnedCalls = {};
//       returnedCalls.name = "Returned Calls";
//       returnedCalls.value = data.data.returnedCalls;
//       downloadData.push(returnedCalls);
//       var returnedCallsByCaller = {};
//       returnedCallsByCaller.name = "Returned By Caller";
//       returnedCallsByCaller.value = data.data.returnedCallsByCaller;
//       downloadData.push(returnedCallsByCaller);
//       var returnedCallsInTime = {};
//       returnedCallsInTime.name = "Returned In Time";
//       returnedCallsInTime.value = data.data.returnedCallsInTime;
//       downloadData.push(returnedCallsInTime);
//       var returnedCallsOutsideTarget = {};
//       returnedCallsOutsideTarget.name = "Returned Outside Target";
//       returnedCallsOutsideTarget.value = data.data.returnedCallsOutsideTarget;
//       downloadData.push(returnedCallsOutsideTarget);
//       var unreturnedCalls = {};
//       unreturnedCalls.name = "Unreturned";
//       unreturnedCalls.value = data.data.unreturnedCalls;
//       downloadData.push(unreturnedCalls);
//       var uniqueMissedCalls = {};
//       uniqueMissedCalls.name = "Unique Missed";
//       uniqueMissedCalls.value = data.data.uniqueMissedCalls;
//       downloadData.push(uniqueMissedCalls);
//       var totalTalkTime = {};
//       totalTalkTime.name = "Total Talk Time";
//       totalTalkTime.value = data.data.totalTalkTime;
//       downloadData.push(totalTalkTime);
//       var avgAnswerTime = {};
//       avgAnswerTime.name = "Average Answer Time";
//       avgAnswerTime.value = data.data.avgAnswerTime;
//       downloadData.push(avgAnswerTime);
//       var longestWaitTime = {};
//       longestWaitTime.name = "Longest Wait Time";
//       longestWaitTime.value = data.data.longestWaitTime;
//       downloadData.push(longestWaitTime);
//       var gos = {};
//       gos.name = "GOS";
//       gos.value = data.data.gos;
//       downloadData.push(gos);
//       var pca = {};
//       pca.name = "PCA";
//       pca.value = data.data.pca;
//       downloadData.push(pca);
//       var avgCallQuality = {};
//       avgCallQuality.name = "Average Call Quality";
//       avgCallQuality.value = data.data.avgCallQuality;
//       downloadData.push(avgCallQuality);

//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');

//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }

//     const StackedBarChartAndGroupedGridDownload = () => 
//     {
//       const downloadData = [];
//       var stacBarcData = data.data.stackedBarChartData;
//       var groupedGridData;
//       if(props.card.widgetID===34)
//         groupedGridData = data.data.gridData;
//       else
//         groupedGridData = data.data.groupedGridData;
//       console.log(groupedGridData);
//       if(stacBarcData.series.length === 3)
//       {
//         var excelHeader = {};
//         excelHeader.name = "";
//         if(props.card.widgetID===34)
//         {
//           excelHeader.variable1 = stacBarcData.series[0].name;
//           excelHeader.variable2 = stacBarcData.series[1].name;
//           excelHeader.variable3 = stacBarcData.series[2].name;
//           excelHeader.variable4 = "";
//           excelHeader.variable5 = "";
//         }
//         else
//         {
//           excelHeader.variable1 = stacBarcData.series[0].name;
//           excelHeader.variable2 = stacBarcData.series[1].name;
//           excelHeader.variable3 = stacBarcData.series[2].name;
//         }
//         downloadData.push(excelHeader);
//         downloadData.push("-","-","-");
//         for (var i = 0; i < stacBarcData.categories.length; i++)
//         {
//           var values = {};
//           if(props.card.widgetID===34)
//           {
//             values.name = stacBarcData.categories[i];
//             values.variable1 = stacBarcData.series[0].data[i];
//             values.variable2 = stacBarcData.series[1].data[i];
//             values.variable3 = stacBarcData.series[2].data[i];
//             values.variable4 = "";
//             values.variable5 = "";
//           }
//           else
//           {
//             values.name = stacBarcData.categories[i];
//             values.variable1 = stacBarcData.series[0].data[i];
//             values.variable2 = stacBarcData.series[1].data[i];
//             values.variable3 = stacBarcData.series[2].data[i];
//           }
//           downloadData.push(values);
//         }
//       }
      
//       for (var i = 0; i < groupedGridData.length; i++)
//       {
//         var gridData = {};
//         if(props.card.widgetID===34)
//         {
//           gridData.name = groupedGridData[i].userName;
//           gridData.variable1 = groupedGridData[i].totalMessages + "(" + groupedGridData[i].external + ")";
//           gridData.variable2 = groupedGridData[i].chats + "(" + groupedGridData[i].oneToOne + ")";
//           gridData.variable3 = groupedGridData[i].posts;
//           gridData.variable4 = groupedGridData[i].replies;
//           gridData.variable5 = groupedGridData[i].sentiment;
//         }
//         else
//         {
//           gridData.name = groupedGridData[i].userName;
//           gridData.variable1 = groupedGridData[i].total;
//           gridData.variable2 = groupedGridData[i].oneToOne;
//           gridData.variable3 = groupedGridData[i].group;
//         }
//         console.log(gridData);
//         downloadData.push(gridData);
//       }
      
//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');
//       console.log(csv);

//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }

//     const TeamEngagementDownload = () => 
//     {
//       const downloadData = [];
//       var meetings = {};
//       meetings.name = "Meetings";
//       meetings.value1 = "Total Meetings:" + data.data.teamMeetings.totalMeetings + ", Internal Meetings:" + data.data.teamMeetings.internalMeetings + 
//                        ", External Meetings:" + data.data.teamMeetings.totalMeetexternalMeetingsings + ", Recurring:" + data.data.teamMeetings.totalRecurringMeetings + 
//                        ", Meeting Time:" + data.data.teamMeetings.totalTimeInMeeting;
//       meetings.value2 = "";  
//       downloadData.push(meetings);
//       var messages = {};
//       messages.name = "Meetings";
//       messages.value1 = "Total Messages:" + data.data.teamMessages.totalMessages + ", Posts/Replies:" + data.data.teamMessages.postsReplies + 
//                        ", Mentions:" + data.data.teamMessages.messageMentions + ", Reactions:" + data.data.teamMessages.messagesReactions + 
//                        ", Sentiment:" + data.data.teamMessages.avgSentiments;
//       messages.value2 = "";  
//       downloadData.push(messages);
//       var CollaborationMsg = {};
//       CollaborationMsg.name = "Collaborators Messages";
//       for (let i=0; i<data.data.teamCollaborators.messageCollabList.length; i++)
//       {
//         if(i===0)
//           CollaborationMsg.value1 = data.data.teamCollaborators.messageCollabList[i].userName + ": " + data.data.teamCollaborators.messageCollabList[i].messageCount;
//         else
//           CollaborationMsg.value1 = CollaborationMsg.value1 + ", " + data.data.teamCollaborators.messageCollabList[i].userName + ": " + data.data.teamCollaborators.messageCollabList[i].messageCount;
//       }
//       CollaborationMsg.value2 = "";
//       downloadData.push(CollaborationMsg);
//       var CollaborationCalls = {};
//       CollaborationCalls.name = "Collaborators Calls";
//       for (let i=0; i<data.data.teamCollaborators.callCollabList.length; i++)
//       {
//         if(i===0)
//           CollaborationCalls.value1 = data.data.teamCollaborators.callCollabList[i].userName + ": " + data.data.teamCollaborators.callCollabList[i].callDuration;
//         else
//           CollaborationCalls.value1 = CollaborationCalls.value1 + ", " + data.data.teamCollaborators.callCollabList[i].userName + ": " + data.data.teamCollaborators.callCollabList[i].callDuration;
//       }
//       CollaborationCalls.value2="";
//       downloadData.push(CollaborationCalls);
//       downloadData.push("-","","-");

//       var excelHeader = {};
//       excelHeader.name = "";
//       excelHeader.value1 = data.data.teamsStackChartData.series[0].name;
//       excelHeader.value2 = data.data.teamsStackChartData.series[1].name;
//       downloadData.push(excelHeader);
//       for (var i = 0; i < data.data.teamsStackChartData.categories.length; i++)
//       {
//         var values = {};
//         values.name = data.data.teamsStackChartData.categories[i];
//         values.value1 = data.data.teamsStackChartData.series[0].data[i];
//         values.value2 = data.data.teamsStackChartData.series[1].data[i];
//         downloadData.push(values);
//       }

//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');

//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }

//     const OnetoOneTrendsDownload = () => 
//     {
//       const downloadData = [];
//       if(data.data.series.length === 2)
//       {
//         var excelHeader = {};
//         excelHeader.name = "";
//         excelHeader.variable1 = data.data.series[0].name;
//         excelHeader.variable2 = data.data.series[1].name;
//         downloadData.push(excelHeader);
//         console.log(downloadData);
//         for (var i = 0; i < data.data.categories.length; i++)
//         {
//           var values = {};
//           values.name = data.data.categories[i];
//           values.variable1 = data.data.series[0].data[i];
//           values.variable2 = data.data.series[1].data[i];
//           downloadData.push(values);
//         }
//         var value1 = {};
//         value1.name = "Average Call Minutes";
//         value1.variable1 = data.data.avgBusinessCallMinutes;
//         value1.variable2 = null;
//         downloadData.push(value1);
//         var value2 = {};
//         value2.name = "Average Messages";
//         value2.variable1 = data.data.avgBusinessMessages;
//         value2.variable2 = null;
//         downloadData.push(value2);
//         console.log(downloadData)
//       }
    
//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');

//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }

//     const ManagementInsightsDownload = () =>
//     {
//       const downloadData = [];
//       var underCoached = {};
//       underCoached.name = "Under Coached";
//       if(data.data.underCoachedList.length > 1)
//       {
//         for (var i = 0; i < data.data.underCoachedList.length; i++ )
//         {
//           if(i === 0)
//             underCoached.value = data.data.underCoachedList[i].name;
//           else if(i === data.data.underCoachedList.length)
//             underCoached.value = underCoached.value + ", " + data.data.underCoachedList[i].name;
//           else
//             underCoached.value = underCoached.value + ", " + data.data.underCoachedList[i].name;
//         }
//       }
//       downloadData.push(underCoached);

//       var coachingList = {};
//       coachingList.name = "Coaching";
//       if(data.data.coachingList.length > 1)
//       {
//         for (var i = 0; i < data.data.coachingList.length; i++ )
//         {
//           if(i === 0)
//           coachingList.value = data.data.coachingList[i].name;
//           else if(i === data.data.coachingList.length)
//           coachingList.value = coachingList.value + ", " + data.data.coachingList[i].name;
//           else
//           coachingList.value = coachingList.value + ", " + data.data.coachingList[i].name;
//         }
//       }
//       downloadData.push(coachingList);

//       var coattendanceList = {};
//       coattendanceList.name = "Co-Attendance";
//       if(data.data.coattendanceList.length > 1)
//       {
//         for (var i = 0; i < data.data.coattendanceList.length; i++ )
//         {
//           if(i === 0)
//           coattendanceList.value = data.data.coattendanceList[i].name;
//           else if(i === data.data.coattendanceList.length)
//           coattendanceList.value = coattendanceList.value + ", " + data.data.coattendanceList[i].name;
//           else
//           coattendanceList.value = coattendanceList.value + ", " + data.data.coattendanceList[i].name;
//         }
//       }
//       downloadData.push(coattendanceList);

//       var highlyManagedList = {};
//       highlyManagedList.name = "Highly Managed";
//       if(data.data.highlyManagedList.length > 1)
//       {
//         for (var i = 0; i < data.data.highlyManagedList.length; i++ )
//         {
//           if(i === 0)
//           highlyManagedList.value = data.data.highlyManagedList[i].name;
//           else if(i === data.data.highlyManagedList.length)
//           highlyManagedList.value = highlyManagedList.value + ", " + data.data.highlyManagedList[i].name;
//           else
//           highlyManagedList.value = highlyManagedList.value + ", " + data.data.highlyManagedList[i].name;
//         }
//       }
//       downloadData.push(highlyManagedList);

//       var leastCoattendance = {};
//       leastCoattendance.name = "Least Co-Attendance";
//       leastCoattendance.value = data.data.leastCoattendance;
//       downloadData.push(leastCoattendance);
//       var leastOneToOne = {};
//       leastOneToOne.name = "Least OneToOne";
//       leastOneToOne.value = data.data.leastOneToOne;
//       downloadData.push(leastOneToOne);
//       var mostCoattendance = {};
//       mostCoattendance.name = "Most Co-Attendance";
//       mostCoattendance.value = data.data.mostCoattendance;
//       downloadData.push(mostCoattendance);
//       var mostOneToOne = {};
//       mostOneToOne.name = "Most OneToOne";
//       mostOneToOne.value = data.data.mostOneToOne;
//       downloadData.push(mostOneToOne);

//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');

//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }

//     const ExpandableRowGridDownload = () => 
//     {
//       const downloadData = [];
//       var team = {};
//         team.name = "Team Name";
//         team.name1 = "Channel Name";
//         team.name2 = "Total Meeting";
//         team.name3 = "% Recurring";
//         team.name4 = "Posts";
//         team.name5 = "Replies";
//         team.name6 = "Sentiment Score";
//         team.name7 = "Active Users";
//         downloadData.push(team);
//       for (let i = 0; i < data.data.gridData.length; i++)
//       {
//         var team1 = {};
//         team1.name = data.data.gridData[i].teamName;
//         team1.name1 = "";
//         team1.name2 = data.data.gridData[i].organizedMeetings;
//         team1.name3 = data.data.gridData[i].perRecurring;
//         team1.name4 = data.data.gridData[i].posts;
//         team1.name5 = data.data.gridData[i].replies;
//         team1.name6 = data.data.gridData[i].sentimentScore;
//         team1.name7 = data.data.gridData[i].activeUsers;
//         downloadData.push(team1);
//         for (let j = 0; j < data.data.gridData[i].channelDetails.length; j++)
//         {
//           var channel = {};
//           channel.name = "";
//           channel.name1 = data.data.gridData[i].channelDetails[j].channelName;
//           channel.name2 = data.data.gridData[i].channelDetails[j].organizedMeetings;
//           channel.name3 = data.data.gridData[i].channelDetails[j].perRecurring;
//           channel.name4 = data.data.gridData[i].channelDetails[j].posts;
//           channel.name5 = data.data.gridData[i].channelDetails[j].replies;
//           channel.name6 = data.data.gridData[i].channelDetails[j].sentimentScore;
//           channel.name7 = data.data.gridData[i].channelDetails[j].activeUsers;
//           downloadData.push(channel);
//         }
//       }
//       var insights1 = {};
//       insights1.name = "Teams engaged Externally %";
//       insights1.name1 = data.data.insights.perEngagedExternally;
//       downloadData.push(insights1);
//       var insights2 = {};
//       insights2.name = "Teams conducting Screen Share %";
//       insights2.name1 = data.data.insights.perScreenShare;
//       downloadData.push(insights2);
//       var insights3 = {};
//       insights3.name = "Active Users";
//       insights3.name1 = data.data.insights.activeUsers;
//       downloadData.push(insights3);
      
//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');
//       csv = csv.substr(49);

//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }

//     const OnetoOneOverviewDownload = () => 
//     {
//       const downloadData = [];
//       var username = {};
//       username.name = "Name";
//       username.value = data.data.name;
//       downloadData.push(username);
//       var email = {};
//       email.name = "Email";
//       email.value = data.data.email;
//       downloadData.push(email);
//       var designation = {};
//       designation.name = "Designation";
//       designation.value = data.data.designation;
//       downloadData.push(designation);
//       var calls = {}; calls.name= "Calls"; calls.value = ""; downloadData.push(calls);
//       var manager = {};
//       manager.name = "Manager";
//       manager.value = "Total: " + data.data.totalCalls.manager.count + ", Duration: " + data.data.totalCalls.manager.duration + ", OneToOne %: " + data.data.totalCalls.manager.oneToone ;
//       downloadData.push(manager);
//       var manager = {};
//       manager.name = "Direct Reports";
//       manager.value = "Total: " + data.data.totalCalls.directReports.count + ", Duration: " + data.data.totalCalls.directReports.duration + ", OneToOne %: " + data.data.totalCalls.directReports.oneToone ;
//       downloadData.push(manager);
//       var manager = {};
//       manager.name = "Other Peoples";
//       manager.value = "Total: " + data.data.totalCalls.otherPeople.count + ", Duration: " + data.data.totalCalls.otherPeople.duration + ", OneToOne %: " + data.data.totalCalls.otherPeople.oneToone ;
//       downloadData.push(manager);
//       var audio = {}; audio.name= "Audio"; audio.value = ""; downloadData.push(audio);
//       var manager = {};
//       manager.name = "Manager";
//       manager.value = "Total: " + data.data.audio.manager.count + ", Duration: " + data.data.audio.manager.duration + ", OneToOne %: " + data.data.audio.manager.oneToone ;
//       downloadData.push(manager);
//       var manager = {};
//       manager.name = "Direct Reports";
//       manager.value = "Total: " + data.data.audio.directReports.count + ", Duration: " + data.data.audio.directReports.duration + ", OneToOne %: " + data.data.audio.directReports.oneToone ;
//       downloadData.push(manager);
//       var manager = {};
//       manager.name = "Other Peoples";
//       manager.value = "Total: " + data.data.audio.otherPeople.count + ", Duration: " + data.data.audio.otherPeople.duration + ", OneToOne %: " + data.data.audio.otherPeople.oneToone ;
//       downloadData.push(manager);
//       var video = {}; video.name= "Video"; video.value = ""; downloadData.push(video);
//       var manager = {};
//       manager.name = "Manager";
//       manager.value = "Total: " + data.data.video.manager.count + ", Duration: " + data.data.video.manager.duration + ", OneToOne %: " + data.data.video.manager.oneToone ;
//       downloadData.push(manager);
//       var manager = {};
//       manager.name = "Direct Reports";
//       manager.value = "Total: " + data.data.video.directReports.count + ", Duration: " + data.data.video.directReports.duration + ", OneToOne %: " + data.data.video.directReports.oneToone ;
//       downloadData.push(manager);
//       var manager = {};
//       manager.name = "Other Peoples";
//       manager.value = "Total: " + data.data.video.otherPeople.count + ", Duration: " + data.data.video.otherPeople.duration + ", OneToOne %: " + data.data.video.otherPeople.oneToone ;
//       downloadData.push(manager);
//       var messages = {}; messages.name= "Message"; messages.value = ""; downloadData.push(messages);
//       var manager = {};
//       manager.name = "Manager";
//       manager.value = "Total: " + data.data.message.manager.total + ", Sentiment: " + data.data.message.manager.onetoOne;
//       downloadData.push(manager);
//       var manager = {};
//       manager.name = "Direct Reports";
//       manager.value = "Total: " + data.data.message.directReports.total + ", Sentiment: " + data.data.message.directReports.onetoOne;
//       downloadData.push(manager);
//       var manager = {};
//       manager.name = "Other Peoples";
//       manager.value = "Total: " + data.data.message.otherPeople.total + ", Sentiment: " + data.data.message.otherPeople.onetoOne;
//       downloadData.push(manager);
//       var messages = {}; messages.name= "OneToOne"; messages.value = ""; downloadData.push(messages);
//       var manager = {};
//       manager.name = "Manager";
//       manager.value = "Total: " + data.data.sentiment.manager.total + ", Sentiment: " + data.data.sentiment.manager.onetoOne;
//       downloadData.push(manager);
//       var manager = {};
//       manager.name = "Direct Reports";
//       manager.value = "Total: " + data.data.sentiment.directReports.total + ", Sentiment: " + data.data.sentiment.directReports.onetoOne;
//       downloadData.push(manager);
//       var manager = {};
//       manager.name = "Other Peoples";
//       manager.value = "Total: " + data.data.sentiment.otherPeople.total + ", Sentiment: " + data.data.sentiment.otherPeople.onetoOne;
//       downloadData.push(manager);

//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');

//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }

//     const OrganizationalChartDownload = () => 
//     {
//       const downloadData = [];
//       var username = {};
//       username.name = "Name";
//       username.value = data.data.name;
//       downloadData.push(username);
//       var designation = {};
//       designation.name = "Designation";
//       designation.value = data.data.designation;
//       downloadData.push(designation);
//       var calls = {};
//       calls.name = "Calls";
//       for(let i=0; i < data.data.callData.length; i++)
//       {
//         if( i === 0)
//           calls.value = data.data.callData[i].userName + "(" + data.data.callData[i].jobTitle + "): " + data.data.callData[i].talkTime;
//         else  
//         calls.value = calls.value + ", " + data.data.callData[i].userName + "(" + data.data.callData[i].jobTitle + "): " + data.data.callData[i].talkTime;
//       }
//       downloadData.push(calls);
//       var messages = {};
//       messages.name = "Messages";
//       for(let i=0; i < data.data.messageData.length; i++)
//       {
//         if( i === 0)
//         messages.value = data.data.messageData[i].userName + "(" + data.data.messageData[i].jobTitle + "): " + data.data.messageData[i].messages;
//         else  
//         messages.value = messages.value + ", " + data.data.messageData[i].userName + "(" + data.data.messageData[i].jobTitle + "): " + data.data.messageData[i].messages;
//       }
//       downloadData.push(messages)

//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');

//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }

//     const DirectReportOverviewDownload = () => 
//     {
//       const downloadData = [];
//       var username = {};
//       username.name = "Name";
//       username.value1 = data.data.userName; username.value2 = "";
//       downloadData.push(username);
//       var email = {};
//       email.name = "Email";
//       email.value1 = data.data.email; email.value2 = "";
//       downloadData.push(email);
//       var designation = {};
//       designation.name = "Designation";
//       designation.value1 = data.data.designation; designation.value2 = "";
//       downloadData.push(designation);
//       var calls = {};
//       calls.name = "Calls";
//       calls.value1 = ""; calls.value2 = "";
//       downloadData.push(calls);
//       for (let i = 0; i < data.data.callData.length; i++)
//       {
//         var callData = {};
//         callData.name = data.data.callData[i].type;
//         callData.value1 = "Count: " + data.data.callData[i].count + ", Duration: " + data.data.callData[i].duration + ", One to One %: " + data.data.callData[i].perOneToOne; 
//         callData.value2 = "";
//         downloadData.push(callData);
//       }
//       for (let i = 0; i < data.data.messageData.length; i++)
//       {
//         var messageData = {};
//         messageData.name = data.data.callData[i].type;
//         messageData.value1 = "Messages: " + data.data.callData[i].messages + ", Sentiment: " + data.data.callData[i].sentiment; 
//         messageData.value2 = "";
//         downloadData.push(messageData);
//       }
//       var insights = {};
//       insights.name = "Insights";
//       insights.value1 = "One to One %:" + data.data.insights.perOneToOneCall + ", Video calls %: " + data.data.insights.perVideoEnabledCalls +
//                         ", Voice Calls %:" + data.data.insights.perVoiceEnabledCalls + ", User with highest calls: " + data.data.insights.userWithhighestCalls +
//                         ", User with lowest calls:" + data.data.insights.userWithLowestCalls + ", Most one to one chats: " + data.data.insights.userWithhighestMessages +
//                         ", Least one to one chats:" + data.data.insights.userWithLowestMessages;
//       insights.value2 = "";
//       downloadData.push(insights);
//       downloadData.push("-","-","-");
//       var excelHeader = {};
//       excelHeader.name = "";
//       excelHeader.value1 = data.data.chartData.series[0].name;
//       excelHeader.value2 = data.data.chartData.series[1].name;
//       downloadData.push(excelHeader);
//       for (var i = 0; i < data.data.chartData.categories.length; i++)
//       {
//         var values = {};
//         values.name = data.data.chartData.categories[i];
//         values.value1 = data.data.chartData.series[0].data[i];
//         values.value2 = data.data.chartData.series[1].data[i];
//         downloadData.push(values);
//       }

//       var items = downloadData;
//       const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
//       const header = Object.keys(items[0]);
//       let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
//       csv.unshift(header.join(','));
//       csv = csv.join('\r\n');

//       //Download the file as CSV
//       var downloadLink = document.createElement("a");
//       var blob = new Blob(["\ufeff", csv]);
//       var url = URL.createObjectURL(blob);
//       downloadLink.href = url;
//       downloadLink.download = props.card.name + ".csv";  //Name the file here
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     }