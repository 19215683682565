export const TeamsThemes = {
    contrast: "constrast",
    dark: "dark",
    default: "default"
};
 
 
 
 
 
export const isLocal = false;
 
const Api_Local = {
    baseUri: "https://localhost:44309/api"
}
 
const Api_Prod = {
    //baseUri: "https://analytics365test.qa.ucsuite.co/api"
    baseUri: "https://app.uk.analytics-365.com/api"
}
 
export const Api = isLocal ? Api_Local : Api_Prod;
 
 
const Auth_Prod = {
    //appId: "aafa4c91-8737-4cd3-be9b-aecf9acc37a6",
    //audience: "api://analytics365test.qa.ucsuite.co/aafa4c91-8737-4cd3-be9b-aecf9acc37a6"
    appId: "ed734a73-73d5-4339-bb60-b078d9fea5a2",
    audience: "api://app.uk.analytics-365.com/ed734a73-73d5-4339-bb60-b078d9fea5a2"
};
 
const Auth_Local = {
    appId: "f91d5071-77fd-4a06-a97f-16a9707e6125",
    cacheLocation: 'localStorage',
    loginPage: 'login',
    signInStartPage: 'signinstart',
    silentTokenStartPage: 'silenttokenstart',
    signInEndPage: 'signinend',
    authenticatedDomains: {
        "https://graph.microsoft.com": "https://graph.microsoft.com"
    }
};
 
export const Auth = isLocal ? Auth_Local : Auth_Prod;