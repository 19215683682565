import DashboardApiService from "../../../services/DashboardApiService";
import AuthHelper from "../../../services/auth-helper";

export default class Impersonation {

    static Impersonate(successCallbackFunction)
    {
        var tenantGUID = "567b307c-5c6f-47a6-9d5b-9edb72f0a8a9";
        var tokenName = "tokenName";
        var expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 60);

        var tokenData = {
            tokenName: tokenName.trim(),
            userEmail: "bob.fleming@countrymatters.co.uk",
            userGUID: "f229584c-9ba7-4a13-9998-909e82b7ee19",
            expiryDate: expiryDate
        }

        AuthHelper.getAccessToken(function (token) {
            DashboardApiService.CreateImpersonationToken(tokenData, tenantGUID, token).then((response) => {
                if (response !== undefined && response !== "") {
                    window.localStorage.setItem("impersonationToken", response.token);
                    window.localStorage.setItem("impersonationMode", true);

                    successCallbackFunction();
                }            
            });
        });
    }

    static GetImpersonatedData(successCallbackFunction) {
        var tenantGUID = "567b307c-5c6f-47a6-9d5b-9edb72f0a8a9";
        var tokenName = "tokenName";
        var expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 60);

        var tokenData = {
            tokenName: tokenName.trim(),
            userEmail: "bob.fleming@countrymatters.co.uk",
            userGUID: "f229584c-9ba7-4a13-9998-909e82b7ee19",
            expiryDate: expiryDate
        }

        var storedToken = window.localStorage.getItem("demoImpersonationToken");

        // if (storedToken != null && storedToken.length > 0) {
        //     successCallbackFunction(storedToken);
        // }
        // else {
        AuthHelper.getAccessToken(function (token) {
            DashboardApiService.CreateImpersonationToken(tokenData, tenantGUID, token).then((response) => {
                if (response !== undefined && response !== "") {
                    successCallbackFunction(response.token);

                    window.localStorage.setItem("demoImpersonationToken", response.token);
                }
            });
        });
        // }

    }

    static GetDemoImpersonationTokenForFutureUsage() {
        var tenantGUID = "567b307c-5c6f-47a6-9d5b-9edb72f0a8a9";
        var tokenName = "tokenName";
        var expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 60);

        var tokenData = {
            tokenName: tokenName.trim(),
            userEmail: "bob.fleming@countrymatters.co.uk",
            userGUID: "f229584c-9ba7-4a13-9998-909e82b7ee19",
            expiryDate: expiryDate
        }

        var storedToken = window.localStorage.getItem("demoImpersonationToken");

        if (storedToken == null || storedToken.length === 0) {
            AuthHelper.getAccessToken(function (token) {
                DashboardApiService.CreateImpersonationToken(tokenData, tenantGUID, token).then((response) => {
                    if (response !== undefined && response !== "") {
                        window.localStorage.setItem("demoImpersonationToken", response.token);
                    }
                });
            });
        }
    }

    static RemoveImpersonation()
    {
        window.localStorage.removeItem("impersonationMode")
        window.localStorage.removeItem("impersonationToken");
    }
}