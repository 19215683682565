import React from "react";
import { useState, useEffect } from "react";
import {
  Flex,
  Form,
  Layout,
  Checkbox,
  Input,
  Grid,
  Button,
  Segment,
  Box,
  Loader,
  Text,
  Dropdown,
  Avatar,
  Label,
  Tooltip,
  Popup,
  RadioGroup,
} from "@fluentui/react-northstar";
import {
  WindowMinimizeIcon,
  PresenceAvailableIcon,
  ExclamationTriangleIcon,
  ContactGroupIcon,
  InfoIcon,
} from "@fluentui/react-icons-northstar";
import "../Dashboard/dashboard.scss";
import "./config.scss";
import ConfigurationApiService from "../../../services/ConfigurationApiService";
import AuthHelper from "../../../services/auth-helper";
import TeamsTimePicker from "../../Shared/uicomponents/TeamsTimePicker";
import NativeTimePicker from "../../Shared/uicomponents/NativeTimePicker";
import WindowContext from "../../Shared/Context/Context";
import { GroupInheritanceIcon, TrialExpiredIcon, LicenseWarningIcon } from "./commonIcons";
import Impersonation from '../Configuration/Impersonation';

const administrativeAccessTypes = [
  { id: 0, header: 'No Admin', key: 0 },
  { id: 2, header: 'Admin', key: 2 },
  { id: 1, header: 'Super Admin', key: 1 }

];
const administrativeAccessTypesTeamOwner = [
  { id: 0, header: 'No Admin', key: 0 },
  { id: 2, header: 'Admin', key: 2 }
];
const administrativeAccessTypesAdminCase = [
  { id: 2, header: 'Admin', key: 2 },
  { id: 1, header: 'Super Admin', key: 1 }

];
const administrativeAccessTypesSuperAdminCase = [
  { id: 1, header: 'Super Admin', key: 1 }

];
const administrativePeople = [
  { id: 0, header: 'Direct Reports', key: 0 },
  { id: 1, header: 'My Reporting Network', key: 1 }
];

const EditProfile = (props) => {
  const [loading, setLoading] = useState(true);
  const [myPeople, setMyPeople] = useState([]);
  const [myGroups, setMyGroups] = useState([]);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [groupInheritanceLoading, setGroupInheritanceLoading] = useState(false);
  const [userGroupInheritance, setUserGroupInheritance] = useState(null);
  const [isProductInherited, setIsProductInherited] = useState(true);
  const [subscription, setsubscription] = useState(null);

  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));

  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, []);

  useEffect(() => {
    loadUserProfileData();
    setUserGroupInheritance(props.user.isGroupInheritanceAllowed);
    setIsProductInherited(props.user.isProductInherited);
    setsubscription(props.productTypes.find(e => e.id === props.user.subscriptionId));
  }, []);


  const handleIsProductInheritedValue = (isChecked, props) => {
    if (!isChecked)
      setIsProductInherited(false);
    else {
      if (props.subscriptionId === props.subscriptionIdInherited)
        setIsProductInherited(false);
      else {
        if (props.subscriptionIdInherited > props.subscriptionId)
          setIsProductInherited(true);
        else
          setIsProductInherited(false);
      }
    }

  }
  const loadUserProfileData = () => {
    setLoading(true);
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetUserProfile(props.user.userGUID, token).then(
        (response) => {
          if (response != undefined && response != "") {
            setMyPeople(response.myPeople);
            setMyGroups(response.myGroups);
          }
          setLoading(false);
          window.dispatchEvent(new Event("resize"));
        }
      );
    });
  };
  const handleUserGroupInheritance = (event, isChecked) => {
    setGroupInheritanceLoading(true);
    var data = {
      userGUID: props.user.userGUID,
      enabled: isChecked.checked
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserGroupInheritance(data, token).then(
        (response) => {
          //debugger;
          if (response !== undefined && response !== "") {
            props.handleUserGroupInheritance(isChecked.checked, props.user);
            setUserGroupInheritance(isChecked.checked);
            handleIsProductInheritedValue(isChecked.checked, props.user);

            renderUserSubscription();
          }
          setGroupInheritanceLoading(false);
        }
      );
    });
  };
  const handleUserSubscription = {
    onAdd: (item) => {
      if (item.id === props.user.subscriptionId || props.user.email === props.loggedInUserEmail
        || (!props.tenantDetails.autoBuySubscription && item.disabled)) {
        return;
      }
      setSubscriptionLoading(true);
      var data = {
        userGUIDList: [props.user.userGUID],
        accessID: item.id,
      };
      AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.UpdateUserSubscription(data, token).then(
          (response) => {
            if (response !== undefined && response !== "") {
              props.handleUserSubscription(item.id, props.user);
              setsubscription(item)
            }
            setSubscriptionLoading(false);
          }
        );
      });
    }
  };
  const renderUserSubscription = () => {
    let item = props.productTypes.find(e => e.id === props.user.subscriptionId);
    let itemInherited = props.productTypes.find(e => e.id === props.user.subscriptionIdInherited % 500);
    let productName = isProductInherited ? itemInherited?.header : item?.header;

    if (props.user.email === props.loggedInUserEmail) {
      return <>
        <Flex space="between">
          <Tooltip
            className="tooltipTxt"
            trigger={
              <Text weight="bold" styles={{ color: "#A4A4A4" }} truncated>
                {isProductInherited ? itemInherited?.header : item?.header}
              </Text>
            }
            align="center"
            position="below"
            content={<div>
              <p style={{ marginBottom: "0px", weight: "bold" }}>{productName}</p>
              {!isProductInherited && ((props.user.email === props.loggedInUserEmail) ? "Edit permission disabled for own account"
                : "Admin can not change Super Admin permissions")}
            </div>
            }
          />
          <Flex gap="gap.small">
            {isProductInherited && Math.floor(props.user.subscriptionIdInherited / 500) === 0 &&
              <GroupInheritanceIcon colorValue={"#A4A4A4"} userGUID={props.user.userGUID} inheritanceType={"subscription"} />
            }
            {isProductInherited && Math.floor(props.user.subscriptionIdInherited / 500) !== 0 &&
              <LicenseWarningIcon colorValue={"#A4A4A4"} type={"user"} />
            }
          </Flex>
        </Flex>
      </>
    }
    else {
      if (!isProductInherited) {
        return <Tooltip
          className="tooltipTxt"
          trigger={
            <Dropdown
              fluid
              disabled={isDemo === true}
              items={props.productTypes}
              value={subscription}
              getA11ySelectionMessage={handleUserSubscription}
              itemToValue={(obj) => {
                return obj.key;
              }}
              onChange={handleIsProductInheritedValue(userGroupInheritance, props.user)}
              renderItem={(Component, props) => {
                if (props.disabled) {
                  props.className = props.className + " _licenseUnavailable";
                }
                return <Component {...props}></Component>;
              }}
            />
          }
          align="center"
          position="before"
          content={item?.header}
        />
      }
      else {
        return <>
          <Flex space="between">
            <Tooltip
              className="tooltipTxt"
              trigger={<Text weight="bold" content={itemInherited?.header} truncated />}
              align="center"
              position="before"
              content={itemInherited?.header}
            />
            <Flex gap="gap.small">
              {Math.floor(props.user.subscriptionIdInherited / 500) === 0 &&
                <GroupInheritanceIcon colorValue="green" userGUID={props.user.userGUID} inheritanceType={"subscription"} />
              }
              {Math.floor(props.user.subscriptionIdInherited / 500) !== 0 &&
                <LicenseWarningIcon colorValue="orange" type={"user"} />
              }
            </Flex>
          </Flex>
        </>
      }
    }
  }

  return (
    <WindowContext.Consumer>
      {(context) =>
        loading ? (
          <Loader label="Loading..." />
        ) : (


          <Box className="h-100">
            <div className={context.mediaCategory.sm ? "flex-column filterLbl d-flex pb-3" : "filterLbl d-flex pb-3"} style={{ columnGap: "1rem" }}>
              <div className="filterLblRow d-flex align-items-center">
                <div className="filterLblRow-txt pr-2">
                  <Text content="Subscription: " className="text-nowrap" />
                </div>
                <div className="filterLblRow-btn pr-2">
                  <Box>{subscriptionLoading ? (<Loader size="small" inline disabled={isDemo === true} />) : (renderUserSubscription())}</Box>
                </div>
                <div className="filterLblRow-icn ml-auto">
                  <Box>
                    <Popup
                      trigger={<InfoIcon outline size="large" />}
                      content={<>
                        <p>Allows A365 to capture data for this user <br />based on product subscription type.</p>
                      </>}
                      pointing
                      trapFocus
                      position={"after"}
                      align={"center"}
                      on="hover"
                    />
                  </Box>
                </div>
              </div>
              <div className="filterLblRow d-flex align-items-center">
                <div className="filterLblRow-txt pr-2">
                  <Text content="Subscription applied via group policy : " />
                </div>
                <div className="filterLblRow-btn">
                  <Box>{groupInheritanceLoading ? (<Loader size="small" inline />) : (
                    <Checkbox
                      className="p-0"
                      toggle
                      checked={userGroupInheritance}
                      onClick={handleUserGroupInheritance}
                      disabled={isDemo === true}
                    />
                  )}</Box>
                </div>
                <div className="filterLblRow-icn ml-auto">
                  <Box>
                    <Popup
                      trigger={<InfoIcon outline size="large" />}
                      content={<>
                        <p>Allows this user to inherit product <br />subscription via group policy.</p>
                      </>}
                      pointing
                      trapFocus
                      position={"after"}
                      align={"center"}
                      on="hover"
                    />
                  </Box>
                </div>
              </div>
            </div>

            <Grid
              className="user-profile"
              columns={2}
              styles={{
                width: "100%",
                marginTop: "0",
                marginBottom: "0",
                alignItems: "top",
              }}>
              <Segment
                className={`user-profile-managerlisting card-bdr-top ${props.focused == 1 ? 'focused' : ''}`}
                styles={{ height: "358px", overflow: "hidden" }}
              >
                <Box style={{ marginBottom: "0.5rem" }}>
                  <Text
                    styles={{
                      color: "#666",
                      lineHeight: 1,
                      marginLeft: "5px",
                    }}
                    content="Direct Reports"
                  />
                </Box>
                {
                  myPeople && myPeople.filter((p) => p.isDirectReportee === true).length > 0 ? (
                    <div style={{ height: "calc(100% - 18px)", overflow: "auto" }}>
                      {
                        myPeople.filter((p) => p.isDirectReportee === true).map((people) => {
                          return (
                            <Layout
                              gap="0.6rem"
                              start={
                                <Avatar
                                  image={people.userImage}
                                  name={people.userName}
                                  size={
                                    context.mediaCategory.sm
                                      ? "medium"
                                      : "large"
                                  }
                                />
                              }
                              main={
                                <div className="user-info">
                                  <Box className="">
                                    <Text
                                      content={people.userName}
                                      size={
                                        context.mediaCategory.sm
                                          ? "small"
                                          : "medium"
                                      }
                                    />
                                  </Box>
                                  <Text
                                    content={people.jobTitle}
                                    size={
                                      context.mediaCategory.sm
                                        ? "smaller"
                                        : "small"
                                    }
                                    timestamp
                                  />
                                </div>
                              }
                              end=""
                              style={{
                                marginTop: "0.5rem !important",
                                marginBottom: "0.5rem",
                              }}
                            />
                          );
                        })
                      }
                    </div>

                  ) : (
                    <Flex
                      column
                      hAlign="center"
                      vAlign="center"
                      gap="gap.small"
                      style={{
                        height: 'calc(100% - 18px)',
                      }}>
                      <ContactGroupIcon
                        className="no-data-icon"
                        outline
                        size="large"
                        style={{ color: "#A9A9A9" }}
                      />
                      <Text
                        content="Data Not Available"
                        size="small"
                        style={{ color: "#A9A9A9" }}
                      />
                    </Flex>
                  )
                }
              </Segment>
              <Segment
                className={`user-profile-managerlisting card-bdr-top ${props.focused == 2 ? 'focused' : ''}`}
                styles={{ height: "358px", overflow: "hidden" }}
              >
                <Box style={{ marginBottom: "0.5rem" }}>
                  <Text
                    styles={{
                      color: "#666",
                      lineHeight: 1,
                      marginLeft: "5px",
                    }}
                    content="My Reporting Network"
                  />
                </Box>
                {
                  myPeople && myPeople.length > 0 ? (

                    <div style={{ height: "calc(100% - 18px)", overflow: "auto" }}>
                      {
                        myPeople.map((people) => {
                          return (
                            <Layout
                              gap="0.6rem"
                              start={
                                <Avatar
                                  image={people.userImage}
                                  name={people.userName}
                                  size={
                                    context.mediaCategory.sm
                                      ? "medium"
                                      : "large"
                                  }
                                />
                              }
                              main={
                                <div className="user-info">
                                  <Box className="">
                                    <Text
                                      content={people.userName}
                                      size={
                                        context.mediaCategory.sm
                                          ? "small"
                                          : "medium"
                                      }
                                    />
                                  </Box>
                                  <Text
                                    content={people.jobTitle}
                                    size={
                                      context.mediaCategory.sm
                                        ? "smaller"
                                        : "small"
                                    }
                                    timestamp
                                  />
                                </div>
                              }
                              end=""
                              style={{
                                marginTop: "0.5rem !important",
                                marginBottom: "0.5rem",
                              }}
                            />
                          );
                        })
                      }
                    </div>
                  ) : (
                    <Flex
                      column
                      hAlign="center"
                      vAlign="center"
                      gap="gap.small"
                      style={{
                        height: 'calc(100% - 18px)',
                      }}>
                      <ContactGroupIcon
                        className="no-data-icon"
                        outline
                        size="large"
                        style={{ color: "#A9A9A9" }}
                      />
                      <Text
                        content="Data Not Available"
                        size="small"
                        style={{ color: "#A9A9A9" }}
                      />
                    </Flex>
                  )}
              </Segment>

            </Grid>
          </Box>
        )
      }
    </WindowContext.Consumer>
  );
};

const EditPermissions = (props) => {
  const [loading, setLoading] = useState(false);
  const [administrativeAccessLoading, setAdministrativeAccessLoading] = useState(false);
  const [reportingAccessLoading, setReportingAccessLoading] = useState(false);
  const [administrativeAccess, setAdministrativeAccess] = useState(null);
  const [reportingAccess, setReportingAccess] = useState(false);

  const [teams, setTeams] = useState([]);
  const [teamsIsSetting, setTeamsIsSetting] = useState([]);

  const [teamsIsGroup, setTeamsIsGroup] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupsIsSetting, setGroupsIsSetting] = useState([]);

  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedTeamIsGroup, setSelectedTeamIsGroup] = useState([]);
  const [selectedTeamsIsSetting, setSelectedTeamsIsSetting] = useState([]);
  const [selectedPeople, setSelectedPeople] = useState([]);  //


  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedGroupsIsSetting, setSelectedGroupsIsSetting] = useState([]);

  const [callQueues, setCallQueues] = useState([]);
  const [callQueuesIsSetting, setCallQueuesIsSetting] = useState([]);

  const [selectedCallQueues, setSelectedCallQueues] = useState([]);
  const [selectedCallQueuesIsSetting, setSelectedCallQueuesIsSetting] = useState([]);

  useEffect(() => {
    setAdministrativeAccess(administrativeAccessTypes.find(e => e.id === props.user.administrativeAccess));
    setReportingAccess(props.user.reportingAccess === 1);
  }, [props.user]);

  useEffect(() => {
    loadGroupsData();

  }, []);

  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));
  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, []);

  const loadGroupsData = (isAdminUpdated) => {
    if (!loading)
      setLoading(true);

    if (props.isLoggedInUserAdmin) {
      AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.GetGroups(token).then((response) => {
          if (response != undefined && response != "") {
            var tempTeams = [];
            var tempGroups = [];
            var tempCallQueues = [];
            response.forEach((item) => {
              var data = {
                header: item.groupName,
                id: item.groupId,
                key: item.groupId,
                disabled: false
              };
              if (item.groupTypeId === 0 && item.objectTypeID !== 2) {
                tempTeams.push(data);
              } else {
                if (item.objectTypeID !== 2)
                  tempGroups.push(data);
              }
              if (item.objectTypeID === 2) {
                tempCallQueues.push(data);
              }
            });

            setTeams(tempTeams);
            setGroups(tempGroups);
            setCallQueues(tempCallQueues);
            setTeamsIsSetting(tempTeams);
            setGroupsIsSetting(tempGroups);
            setCallQueuesIsSetting(tempCallQueues);
            setTeamsIsGroup(tempTeams);
            setLoading(false);
            loadUserPermissions(response, isAdminUpdated, tempTeams, tempTeams);
          }
          else {
            if (loading)
              setLoading(false);
          }
        });
      });
    }
    else {
      AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.GetUserPermissions(props.loggedInUserGUID, token).then((response) => {
          if (response != undefined && response != "") {
            var tempTeams = [];
            var tempGroups = [];
            var tempCallQueues = [];
            var tempTeamsIsSetting = [];
            var tempGroupsIsSetting = [];
            var tempCallQueuesIsSetting = [];
            var tempTeamIsGroupData = [];
            response.forEach((element) => {
              var ob = {
                id: element.groupId,
                header: element.groupName,
                key: element.groupId,
                disabled: false
              };

              if (element.isMSTeam) {

                element.isNotReporting ? tempTeamsIsSetting.push(ob) : element.teamIsGroup ? tempTeamIsGroupData.push(ob) : tempTeams.push(ob);


              } else if (element.objectType === 0) {
                element.isNotReporting ? tempGroupsIsSetting.push(ob) : tempGroups.push(ob);

              } else if (element.objectType === 2) {
                element.isNotReporting ? tempCallQueuesIsSetting.push(ob) : tempCallQueues.push(ob);

              }


            });
            setTeams(tempTeams);
            setGroups(tempGroups);
            setCallQueues(tempCallQueues);

            setTeamsIsGroup(tempTeamIsGroupData);
            setTeamsIsSetting(tempTeamsIsSetting);
            setGroupsIsSetting(tempGroupsIsSetting);
            setCallQueuesIsSetting(tempCallQueuesIsSetting);
            if (loading)
              setLoading(false);
            loadUserPermissions(response, false, tempTeams, tempTeamIsGroupData);
          }
          else {
            if (loading)
              setLoading(false);
          }
        });
      });
    }
  };

  // const loadGroupsData1 = (isAdminUpdated) => {

  //   if (props.isLoggedInUserAdmin) {
  //     AuthHelper.getAccessToken(function (token) {
  //       ConfigurationApiService.GetGroups(token).then((response) => {
  //         if (response != undefined && response != "") {
  //           var tempTeams = [];
  //           var tempGroups = [];
  //           var tempCallQueues = [];
  //           var tempTeamIsGroupData = [];

  //           response.forEach((item) => {
  //             var data = {
  //               header: item.groupName,
  //               id: item.groupId,
  //               key: item.groupId,
  //               disabled: false
  //             };
  //             if (item.groupTypeId === 0 && item.objectTypeID !== 2) {
  //               tempTeams.push(data);
  //             } else {
  //               if (item.objectTypeID !== 2)
  //                 tempGroups.push(data);
  //             }
  //             if (item.objectTypeID === 2) {
  //               tempCallQueues.push(data);
  //             }
  //           });
  //           setTeams(tempTeams);
  //           setGroups(tempGroups);
  //           setCallQueues(tempCallQueues);
  //           setTeamsIsSetting(tempTeams);
  //           setGroupsIsSetting(tempGroups);
  //           setCallQueuesIsSetting(tempCallQueues);
  //           setTeamsIsGroup(tempTeams);

  //           loadUserPermissions(response, isAdminUpdated);
  //         }
  //         else {

  //         }
  //       });
  //     });
  //   }
  //   else {
  //     AuthHelper.getAccessToken(function (token) {
  //       ConfigurationApiService.GetUserPermissions(props.loggedInUserGUID, token).then((response) => {
  //         if (response != undefined && response != "") {
  //           var tempTeams = [];
  //           var tempGroups = [];
  //           var tempCallQueues = [];
  //           var tempTeamsIsSetting = [];
  //           var tempGroupsIsSetting = [];
  //           var tempCallQueuesIsSetting = [];
  //           var tempTeamIsGroupData = [];
  //           response.forEach((element) => {
  //             var ob = {
  //               id: element.groupId,
  //               header: element.groupName,
  //               key: element.groupId,
  //               disabled: false
  //             };
  //             // if (element.isMSTeam) {
  //             //   tempTeams.push(ob);
  //             //   if(element.teamIsGroup){
  //             //     tempTeamIsGroupData.push(ob);
  //             //   }
  //             // } else {
  //             //   tempGroups.push(ob);
  //             // }

  //             if (element.isMSTeam) {

  //               element.isNotReporting ? tempTeamsIsSetting.push(ob) : element.teamIsGroup ? tempTeamIsGroupData.push(ob) : tempTeams.push(ob);

  //             } else if (element.objectType === 0) {
  //               element.isNotReporting ? tempGroupsIsSetting.push(ob) : tempGroups.push(ob);

  //             } else if (element.objectType === 2) {
  //               element.isNotReporting ? tempCallQueuesIsSetting.push(ob) : tempCallQueues.push(ob);

  //             }


  //           });
  //           setTeams(tempTeams);
  //           setGroups(tempGroups);
  //           setCallQueues(tempCallQueues);

  //           setTeamsIsGroup(tempTeamIsGroupData);

  //           setTeamsIsSetting(tempTeamsIsSetting);
  //           setGroupsIsSetting(tempGroupsIsSetting);
  //           setCallQueuesIsSetting(tempCallQueuesIsSetting);

  //           loadUserPermissions(response);
  //         }
  //         else {

  //         }
  //       });
  //     });
  //   }
  // };

  const loadUserPermissions = (_groupData, isAdminUpdated, tempTeams, tempTeamIsGroupData) => {
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetUserPermissions(props.user.userGUID, token).then((response) => {

        if (response != undefined && response != "") { //console.log(response);

          var selectedTeamsData = [];
          var selectedGroupsData = [];
          var selectedTeamIsGroupData = [];
          var selectedCallQueuesData = [];
          var selectedTeamsDataIsSetting = [];
          var selectedGroupsDataIsSetting = [];
          var selectedCallQueuesDataIsSetting = [];
          var selectedPeopleVar = [];

          response.forEach((element) => {
            var ob = {
              id: element.groupId,
              header: element.groupName,
              key: element.groupId,
              disabled: (element.objectType === 3
                || (element.isMSTeam && (tempTeams.filter((x) => x.id === element.groupId).length === 0))
                || ((element.objectType === 0 || element.objectType === 2) && (_groupData.filter((x) => x.id === element.groupId) === -1)))
            };
            var ob_teamIsGroup = {
              id: element.groupId,
              header: element.groupName,
              key: element.groupId,
              disabled: (element.objectType === 3 || tempTeamIsGroupData.filter((x) => x.id === element.groupId).length === 0)
            };
            if (element.isMSTeam) {

              /// if(element.teamIsGroup && !element.isNotReporting)
              element.isNotReporting ? selectedTeamsDataIsSetting.push(ob) : element.teamIsGroup ? selectedTeamIsGroupData.push(ob_teamIsGroup) : selectedTeamsData.push(ob);

            } else if (element.objectType === 0) {
              element.isNotReporting ? selectedGroupsDataIsSetting.push(ob) : selectedGroupsData.push(ob);

            } else if (element.objectType === 2) {
              element.isNotReporting ? selectedCallQueuesDataIsSetting.push(ob) : selectedCallQueuesData.push(ob);

            }
            else if (element.objectType === 3) {
              selectedPeopleVar.push(ob);

            }
          });


          setSelectedTeams(selectedTeamsData);
          setSelectedGroups(selectedGroupsData);
          setSelectedTeamIsGroup(selectedTeamIsGroupData);
          setSelectedCallQueues(selectedCallQueuesData);

          setSelectedCallQueuesIsSetting(selectedCallQueuesDataIsSetting);
          setSelectedTeamsIsSetting(selectedTeamsDataIsSetting);
          setSelectedGroupsIsSetting(selectedGroupsDataIsSetting);
          //setSelectedPeople(selectedPeopleVar);
          if (isAdminUpdated) {
            var ob = {
              id: 0,
              header: "Direct Reports",
              key: 0,
              disabled: 0
            };
            var selectedPeopleVar = [];
            selectedPeopleVar.push(ob);

            if (props.user.administrativeAccess !== 1 && props.user.administrativeAccessInherited !== 1 &&
              (props.user.administrativeAccess === 2 || props.user.administrativeAccessInherited === 2))
              setSelectedPeople(selectedPeopleVar);
          }
          else {
            setSelectedPeople(selectedPeopleVar);
          }

        }

        setLoading(false);
      });
    });
  };

  const handleTeamSelection = {
    onAdd: (item) => {
      setSelectedTeams(selectedTeams.concat(item));
      // handleTeamSelectionIsSetting.onRemove(item);
    },
    onRemove: (item) => {
      if (item.disabled) {
        return;
      }
      let array = [...selectedTeams];
      let index = array.findIndex(element => element.id === item.id);
      if (index !== -1) {
        array.splice(index, 1);
        setSelectedTeams(array);
      }

    },
  };

  const handleTeamIsGroupSelection = {
    onAdd: (item) => {
      if (item.disabled) {
        return;
      }
      let teamIsGroupData = [...selectedTeamIsGroup, item];
      setSelectedTeamIsGroup(teamIsGroupData);
    },
    onRemove: (item) => {
      if (item.disabled) {
        return;
      }
      let array = [...selectedTeamIsGroup];
      let index = array.findIndex(element => element.id === item.id);
      if (index !== -1) {
        array.splice(index, 1);
        setSelectedTeamIsGroup(array);
      }


    },
  };

  const handleAllTeamIsGroup = (checked) => {
    if (checked) {
      let data = selectedTeams.filter((e) => !e.disabled && teamsIsGroup.findIndex((item) => item.id === e.id) > -1).concat(selectedTeamIsGroup.filter((e) => e.disabled));
      setSelectedTeamIsGroup(data);
    } else {
      let data = selectedTeamIsGroup.filter((e) => e.disabled);
      setSelectedTeamIsGroup(data);
    }
  }

  const handleGroupSelection = {
    onAdd: (item) => {
      setSelectedGroups(selectedGroups.concat(item));
      //handleGroupSelectionIsSetting.onRemove(item);

    },
    onRemove: (item) => {
      if (item.disabled) {
        return;
      }
      let array = [...selectedGroups];
      let index = array.findIndex(element => element.id === item.id);
      if (index !== -1) {
        array.splice(index, 1);
        setSelectedGroups(array);
      }
    },
  };

  const handleCallQueueSelection = {
    onAdd: (item) => {
      setSelectedCallQueues(selectedCallQueues.concat(item));
      // handleCallQueueSelectionIsSetting.onRemove(item);
    },
    onRemove: (item) => {
      if (item.disabled) {
        return;
      }
      let array = [...selectedCallQueues];
      let index = array.findIndex(element => element.id === item.id);
      if (index !== -1) {
        array.splice(index, 1);
        setSelectedCallQueues(array);
      }
    },
  };

  const handleTeamSelectionIsSetting = {
    onAdd: (item) => {
      setSelectedTeamsIsSetting(selectedTeamsIsSetting.concat(item));
      //  handleTeamSelection.onRemove(item);
      // handleTeamIsGroupSelection.onRemove(item);

    },
    onRemove: (item) => {
      if (item.disabled) {
        return;
      }
      let array = [...selectedTeamsIsSetting];
      let index = array.findIndex(element => element.id === item.id);
      if (index !== -1) {
        array.splice(index, 1);
        setSelectedTeamsIsSetting(array);
      }

    },
  };

  const handleTeamSelectionPeople = {
    onAdd: (item) => {
      setSelectedPeople(selectedPeople.concat(item));


    },
    onRemove: (item) => {
      if (item.disabled) {
        return;
      }
      let array = [...selectedPeople];
      let index = array.findIndex(element => element.id === item.id);
      if (index !== -1) {
        array.splice(index, 1);
        setSelectedPeople(array);
      }

    },
  };

  const handleGroupSelectionIsSetting = {
    onAdd: (item) => {
      setSelectedGroupsIsSetting(selectedGroupsIsSetting.concat(item));
      //  handleGroupSelection.onRemove(item);
    },
    onRemove: (item) => {
      if (item.disabled) {
        return;
      }
      let array = [...selectedGroupsIsSetting];
      let index = array.findIndex(element => element.id === item.id);
      if (index !== -1) {
        array.splice(index, 1);
        setSelectedGroupsIsSetting(array);
      }
    },
  };
  const handleCallQueueSelectionIsSetting = {
    onAdd: (item) => {
      setSelectedCallQueuesIsSetting(selectedCallQueuesIsSetting.concat(item));
      // handleCallQueueSelection.onRemove(item);
    },
    onRemove: (item) => {
      if (item.disabled) {
        return;
      }
      let array = [...selectedCallQueuesIsSetting];
      let index = array.findIndex(element => element.id === item.id);
      if (index !== -1) {
        array.splice(index, 1);
        setSelectedCallQueuesIsSetting(array);
      }
    },
  };

  //const handleUserGroupInheritance = (event, isChecked) => {
  //  setGroupInheritanceLoading(true);
  //  var data = {
  //    userGUID: props.user.userGUID,
  //    enabled: isChecked.checked
  //  };
  //  AuthHelper.getAccessToken(function (token) {
  //    ConfigurationApiService.UpdateUserGroupInheritance(data, token).then(
  //      (response) => {
  //        if (response !== undefined && response !== "") {
  //          props.handleUserGroupInheritance(isChecked.checked, props.user);
  //          setUserGroupInheritance(isChecked.checked);
  //        }
  //        setGroupInheritanceLoading(false);
  //      }
  //    );
  //  });
  //};

  const handleAdministrativeAccess = {
    onAdd: (item) => {
      setAdministrativeAccessLoading(true);
      var data = {
        userGUIDList: [props.user.userGUID],
        accessID: item.id,
      };
      if (item.id === 0) {

        setSelectedCallQueuesIsSetting([]);
        setSelectedTeamsIsSetting([]);
        setSelectedGroupsIsSetting([]);
      }

      //if (item.id === 0 || item.id === 1) {
      // setSelectedPeople([]);
      //}

      AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.UpdateAdministrativeAccess(data, token).then(
          (response) => {
            if (response !== undefined && response !== "") {
              props.handleUserAdministrativeAccess(item.id, props.user);
              setAdministrativeAccess(item);
              loadGroupsData(true);

              if (item.id === 2 && selectedPeople.length === 0) {
                var selectedPeopleVar = [];
                //setSelectedPeople([]);

                var ob = {
                  id: 0,
                  header: "Direct Reports",
                  key: 0,
                  disabled: -1
                };
                selectedPeopleVar.push(ob);
                setSelectedPeople(selectedPeopleVar);
              }
            }
            setAdministrativeAccessLoading(false);
          }
        );
      });
    }
  };

  const handleRepotingAccess = (event, isChecked) => {
    setReportingAccessLoading(true);
    var data = {
      userGUIDList: [props.user.userGUID],
      accessID: isChecked.checked ? 1 : 0,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateReportingAccess(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            props.handleUserReportingAccess(isChecked.checked ? 1 : 0, props.user);
            setReportingAccess(isChecked.checked);
          }
          setReportingAccessLoading(false);
        }
      );
    });
  };

  //const handleUserSubscription = {
  //  onAdd: (item) => {
  //    if(item.id === props.user.subscriptionId || props.user.email === props.loggedInUserEmail 
  //      || (!props.tenantDetails.autoBuySubscription && item.disabled)){
  //      return;
  //    }
  //    setSubscriptionLoading(true);
  //    var data = {
  //      userGUIDList: [props.user.userGUID],
  //      accessID: item.id,
  //    };
  //    AuthHelper.getAccessToken(function (token) {
  //      ConfigurationApiService.UpdateUserSubscription(data, token).then(
  //        (response) => {
  //          if (response !== undefined && response !== "") {
  //            props.handleUserSubscription(item.id, props.user);
  //            setsubscription(item)
  //          }
  //          setSubscriptionLoading(false);
  //        }
  //      );
  //    });
  //  }
  //};

  const submitPermissions = () => {
    setLoading(true);
    var data = {};
    if (reportingAccess || props.user.reportingAccessInherited === 1 || props.user.administrativeAccess === 2
      || props.user.administrativeAccessInherited === 2) {
      data = {
        userGUID: props.user.userGUID,
        groupPermissions: selectedGroups.map((el) => {
          return { Id: el.id, isNotReporting: false }
        }),
        teamPermissions: selectedTeams.map((el) => {
          //var checkTeamIsGroup = selectedTeamIsGroup.findIndex((item) => item.id === el.id) > -1;
          return { Id: el.id, TeamIsGroup: false, isNotReporting: false }
        }),
        teamPermissionsIsGroup: selectedTeamIsGroup.map((el) => {
          //var checkTeamIsGroup = selectedTeamIsGroup.findIndex((item) => item.id === el.id) > -1;
          return { Id: el.id, TeamIsGroup: true, isNotReporting: false }
        }),
        callQueuePermissions: selectedCallQueues.map((el) => {
          return { Id: el.id, isNotReporting: false }
        }),
        groupPermissionsIsSetting: selectedGroupsIsSetting.map((el) => {
          return { Id: el.id, isNotReporting: true }
        }),
        teamPermissionsIsSetting: selectedTeamsIsSetting.map((el) => {
          //var checkTeamIsGroup = selectedTeamIsGroup.findIndex((item) => item.id === el.id) > -1;
          return { Id: el.id, isNotReporting: true }
        }),
        callQueuePermissionsIsSetting: selectedCallQueuesIsSetting.map((el) => {
          return { Id: el.id, isNotReporting: true }
        }),
        peoplePermissions: selectedPeople.map((el) => {
          return { Id: el.id, isNotReporting: true }
        }),
      };
    } else {
      data = {
        userGUID: props.user.userGUID,
        groupPermissions: [],
        teamPermissions: [],
        callQueuePermissions: [],
      };
      setSelectedTeams([]);
      setSelectedGroups([]);
      setSelectedTeamIsGroup([]);
      setSelectedCallQueues([]);
      setSelectedTeamsIsSetting([]);
      setSelectedGroupsIsSetting([]);
      setSelectedCallQueuesIsSetting([]);
      setSelectedPeople([]);

    }
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserPermissions(data, token).then(
        (response) => {
          if (response != undefined && response != "") {
          }

          loadGroupsData();

          setLoading(false);
        }
      );
    });



  };


  const renderAdministrativeAccess = () => {
    if (props.user.email == props.loggedInUserEmail && (props.user.administrativeAccess === 1 || (props.user.administrativeAccessInherited !== 1 && props.user.administrativeAccess === 2)
      || (props.user.administrativeAccessInherited !== 1 && props.user.administrativeAccessInherited !== 2 && props.user.administrativeAccess === 0))) {
      let access = administrativeAccessTypes.find(e => e.id === props.user.administrativeAccess);
      return <>
        <Tooltip
          className="tooltipTxt"
          trigger={
            <Text weight="bold" styles={{ color: "#A4A4A4" }}>{access.header}</Text>
          }
          align="center"
          position="below"
          content={"Edit permission disabled for own account"}
        />
      </>
    }
    else if (!props.isLoggedInUserAdmin && props.user.administrativeAccess === 1) {
      return <>
        <Tooltip
          className="tooltipTxt"
          trigger={
            <Text weight="bold" styles={{ color: "#A4A4A4" }}>Super Admin </Text>
          }
          align="center"
          position="below"
          content={"Admin can not change Super Admin permissions"}
        />
      </>
    }
    else if (props.user.administrativeAccess === 1 || (props.user.administrativeAccessInherited !== 1 && props.user.administrativeAccess === 2)
      || (props.user.administrativeAccessInherited !== 1 && props.user.administrativeAccessInherited !== 2 && props.user.administrativeAccess === 0)) {

      return <Dropdown
        fluid
        disabled={isDemo === true}
        items={props.isLoggedInUserAdmin ? administrativeAccessTypes : administrativeAccessTypesTeamOwner}
        value={administrativeAccess}
        getA11ySelectionMessage={handleAdministrativeAccess}
        itemToValue={(obj) => {
          return obj.key;
        }}
      />

    }
    else if (props.user.administrativeAccessInherited === 1) {
      return <>
        {!props.isLoggedInUserAdmin && <Text weight="bold" styles={{ marginRight: "20px" }}>Super Admin </Text>
        }
        {!props.isLoggedInUserAdmin && <GroupInheritanceIcon colorValue={"green"} userGUID={props.user.userGUID} inheritanceType={"administrative"} />}
        {props.isLoggedInUserAdmin && <Flex>
          <Dropdown
            styles={{ marginRight: "15px" }}
            fluid
            items={administrativeAccessTypesSuperAdminCase}
            value={"Super Admin"}
            getA11ySelectionMessage={handleAdministrativeAccess}
            itemToValue={(obj) => {
              return obj.key;
            }}
          />
          <GroupInheritanceIcon colorValue={"green"} userGUID={props.user.userGUID} inheritanceType={"administrative"} />
        </Flex>}</>
    }
    else if (props.user.administrativeAccessInherited === 2) {

      return <>
        {!props.isLoggedInUserAdmin && <Text weight="bold" styles={{ marginRight: "15px" }}>Admin </Text>}
        {!props.isLoggedInUserAdmin && <GroupInheritanceIcon colorValue={"green"} userGUID={props.user.userGUID} inheritanceType={"administrative"} />}
        {props.isLoggedInUserAdmin && <Flex> <Dropdown
          styles={{ marginRight: "15px" }}
          fluid
          items={administrativeAccessTypesAdminCase}
          value={"Admin"}
          getA11ySelectionMessage={handleAdministrativeAccess}
          itemToValue={(obj) => {
            return obj.key;
          }}
        />
          <GroupInheritanceIcon colorValue={"green"} userGUID={props.user.userGUID} inheritanceType={"administrative"} />
        </Flex>}</>
    }
    else {
      return <LicenseWarningIcon type={"user"} />
    }

  }


  return loading ? (
    <Loader label="Loading..." />
  ) : (
    <WindowContext>{
      (context) => (
        <Box className="permissions-group">
          <Grid
            className="whRow mt-0"
            columns={5}
            styles={{
              width: "100%",
              marginTop: "20px",
              alignItems: "center",
              gridColumnGap: "8px",
              gridTemplateColumns: "140px 180px 50px 190px 50px calc(100% - 640px)",
            }}
          >

          </Grid>
          <Grid
            className="whRow d-flex align-items-center"
            columns={3}
            styles={{
              //width: "10%",
              justifyContent: "left",
              gridColumnGap: "8px",
              gridTemplateColumns: "auto",
            }}
          >
            <Text content="Reporting Access :" className="text-nowrap" style={{ fontWeight: "600" }} />
            <Box>{reportingAccessLoading ? (<Loader size="small" inline />) : (
              !reportingAccess && props.user.reportingAccessInherited !== 0 ? (
                props.user.reportingAccessInherited === 1 ?
                  <Box styles={{ marginLeft: "13px" }}><GroupInheritanceIcon colorValue={"green"} userGUID={props.user.userGUID} inheritanceType={"reporting"} /></Box> :
                  <Box styles={{ marginLeft: "13px" }}><LicenseWarningIcon type={"user"} /></Box>
              ) : (
                <Box>
                  <Checkbox
                    toggle
                    checked={reportingAccess}
                    disabled={isDemo === true}
                    onClick={handleRepotingAccess}
                  />
                </Box>
              )
            )}</Box>
            <Box className="text-secondary">
              <Popup
                trigger={<InfoIcon outline size="large" />}
                content={<>
                  <p>Allows user to access dashboards.</p>
                </>}
                pointing
                trapFocus
                align={"center"}
                on="hover"
              />
            </Box>
          </Grid>
          {/* <Grid
        className="whRow"
        columns={3}
        styles={{
          width: "100%",
          alignItems: "center",
          gridColumnGap: "8px",
          gridTemplateColumns: "140px 180px calc(100% - 336px)",
        }}
      >
        <Text content="Reporting Access : " weight="semibold" />
        <Box>{reportingAccessLoading ? (<Loader size="small" inline />) : (
          !reportingAccess && props.user.reportingAccessInherited !== 0 ? (
            props.user.reportingAccessInherited === 1 ?
            <Box styles={{marginLeft:"13px"}}><GroupInheritanceIcon colorValue={"green"} userGUID={props.user.userGUID} inheritanceType={"reporting"}/></Box> : 
            <Box styles={{marginLeft:"13px"}}><LicenseWarningIcon /></Box>
          ) : (
            <Box>
              <Checkbox
                toggle
                checked={reportingAccess}
                onClick={handleRepotingAccess}
              />
            </Box>
          )
        )}</Box>
        <Box>
          <Popup
            trigger={<InfoIcon outline size="large"/>}
            content={<>
              <p>Allows user to access dashboards.</p>
            </>}
            pointing
            trapFocus
            position={"after"}
            align={"center"}
            on="hover"
          />
        </Box>
      </Grid> */}
          {(reportingAccess || props.user.reportingAccessInherited === 1
            || props.user.administrativeAccess === 2 || props.user.administrativeAccessInherited === 2
          ) && (
              <>
                <Grid
                  className="whRow mt-0"
                  columns={3}
                  styles={{
                    width: "100%",
                    marginTop: "20px",
                    alignItems: "baseline",
                    gridColumnGap: "8px",
                    gridTemplateColumns: "1fr auto 1fr",
                  }}
                >
                  <Box>

                    <Grid
                      className="whRow mt-2 d-flex"
                      columns={3}
                      styles={{
                        width: "100%",
                        marginTop: "20px",
                        alignItems: "center",
                        justifyContent: "left",
                        gridColumnGap: "8px",
                        gridTemplateColumns: "3fr 6fr auto",
                      }}
                    >
                      <Text content="People :" className="text-nowrap" />
                      <Box style={{ fontSize: `${context.mediaCategory.sm ? ".8em" : ""}` }}>
                        <Text className="profile-link" onClick={() => props.handleProfileClick(2)} content="My Reporting Network" weight="regular" />
                        <Text content=" | " weight="regular" />
                        <Text className="profile-link" onClick={() => props.handleProfileClick(1)} content="Direct Reports" weight="regular" />
                      </Box>
                    </Grid>

                    <Grid
                      className="whRow mt-2"
                      columns={2}
                      styles={{
                        width: "100%",
                        marginTop: "20px",
                        alignItems: "Center",
                        gridColumnGap: "8px",
                        gridTemplateColumns: "3fr 6fr",
                      }}
                    >
                      <Text content="Teams : " className="text-nowrap" />
                      <Grid
                        columns={2}
                        styles={{
                          width: "100%",
                          gridColumnGap: "4px",
                          gridTemplateColumns: "1fr auto", alignItems: "center"
                        }}>
                        <Dropdown
                          fluid
                          styles={{ marginTop: "5px" }}
                          search
                          multiple
                          items={teams}
                          placeholder="Select Teams"
                          getA11ySelectionMessage={handleTeamSelection}
                          value={selectedTeams}
                          itemToValue={(obj) => {
                            return obj.key;
                          }}
                          renderSelectedItem={(Component, props) => {
                            if (props.disabled) {
                              props.className = props.className + " _disabledItem";
                              props.icon = null;
                            }
                            return <Component {...props}></Component>;
                          }}
                        />
                        <Box className="text-secondary">
                          <Popup
                            trigger={<InfoIcon outline size="large" />}
                            content={<>
                              <p>Enables reporting access on <br />selected teams.</p>
                            </>}
                            pointing
                            trapFocus
                            position={"after"}
                            align={"center"}
                            on="hover"
                          />
                        </Box>

                      </Grid>
                    </Grid>

                    <Grid
                      className="whRow"
                      columns={2}
                      styles={{
                        width: "100%",
                        marginTop: "10px",
                        alignItems: "center",
                        gridColumnGap: "8px",
                        gridTemplateColumns: "3fr 6fr",
                      }}
                    >
                      <Text content="Call Queues :" className="text-nowrap" />
                      <Grid
                        columns={2}
                        styles={{
                          width: "100%",
                          gridColumnGap: "4px",
                          gridTemplateColumns: "1fr auto", alignItems: "center"
                        }}
                      >
                        <Dropdown
                          fluid
                          search
                          multiple
                          items={callQueues}
                          placeholder="Select Call Queues"
                          getA11ySelectionMessage={handleCallQueueSelection}
                          value={selectedCallQueues}
                          itemToValue={(obj) => {
                            return obj.key;
                          }}
                          renderSelectedItem={(Component, props) => {
                            if (props.disabled) {
                              props.className = props.className + " _disabledItem";
                              props.icon = null;
                            }
                            return <Component {...props}></Component>;
                          }}
                        />
                        <Box className="text-secondary">
                          <Popup
                            trigger={<InfoIcon outline size="large" />}
                            content={<>
                              <p>Enables reporting access on <br />selected call queues.</p>
                            </>}
                            pointing
                            trapFocus
                            position={"after"}
                            align={"center"}
                            on="hover"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      className="whRow"
                      columns={1}
                      styles={{
                        justifyContent: "center",
                        width: "100%",
                        marginTop: "20px",
                        alignItems: "center",
                        gridColumnGap: "8px",
                        gridTemplateColumns: "1fr",
                      }}
                    >
                      <Text content="Add People to My Reporting Network" style={{ fontWeight: "600" }} />
                    </Grid>
                    <Grid
                      className="whRow"
                      columns={2}
                      styles={{
                        width: "100%",
                        marginTop: "8px",
                        alignItems: "center",
                        gridColumnGap: "8px",
                        gridTemplateColumns: "3fr 6fr",
                      }}>

                      <Text content="via Teams :" className="text-nowrap" />
                      <Grid
                        columns={2}
                        styles={{
                          width: "100%",
                          gridColumnGap: "4px",
                          gridTemplateColumns: "1fr auto", alignItems: "center"
                        }}>
                        <Dropdown
                          fluid
                          styles={{ marginTop: "5px" }}
                          search
                          multiple
                          // items={selectedTeams.filter((el) => !el.disabled && teamsIsGroup.findIndex((item) => item.id === el.id) > -1)}
                          items={teamsIsGroup}
                          placeholder="Select Teams"
                          // headerMessage={
                          //   <Checkbox
                          //     checked={allTeamIsGroup}
                          //     onChange={(e, { checked }) => {handleAllTeamIsGroup(checked)}}
                          //     label={<Text content="Select All" />}
                          //   />
                          // }
                          getA11ySelectionMessage={handleTeamIsGroupSelection}
                          value={selectedTeamIsGroup}
                          itemToValue={(obj) => {
                            return obj.key;
                          }}
                          renderItem={(Component, props) => {
                            if (props.disabled) {
                              props.className = props.className + " _disabledItem";
                            }
                            return <Component {...props}></Component>;
                          }}
                          renderSelectedItem={(Component, props) => {
                            if (props.disabled) {
                              props.className = props.className + " _disabledItem";
                              props.icon = null;
                            }
                            return <Component {...props}></Component>;
                          }}
                        />
                        <Box className="text-secondary">
                          <Popup
                            trigger={<InfoIcon outline size="large" />}
                            content={<>
                              <p>Enables reporting access on <br />activity outside selected teams.</p>
                            </>}
                            pointing
                            trapFocus
                            position={"after"}
                            align={"center"}
                            on="hover"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      className="whRow"
                      columns={2}
                      styles={{
                        width: "100%",
                        marginTop: "10px",
                        alignItems: "center",
                        gridColumnGap: "8px",
                        gridTemplateColumns: "3fr 6fr",
                      }}
                    >
                      <Text content="via Groups :" className="text-nowrap" />
                      <Grid
                        columns={2}
                        styles={{
                          width: "100%",
                          gridColumnGap: "4px",
                          gridTemplateColumns: "1fr auto", alignItems: "center"
                        }}
                      >
                        <Dropdown
                          fluid
                          search
                          multiple
                          items={groups}
                          placeholder="Select Groups"
                          getA11ySelectionMessage={handleGroupSelection}
                          value={selectedGroups}
                          itemToValue={(obj) => {
                            return obj.key;
                          }}
                          renderSelectedItem={(Component, props) => {
                            if (props.disabled) {
                              props.className = props.className + " _disabledItem";
                              props.icon = null;
                            }
                            return <Component {...props}></Component>;
                          }}
                        />
                        <Box className="text-secondary">
                          <Popup
                            trigger={<InfoIcon outline size="large" />}
                            content={<>
                              <p>Enables reporting access on <br />selected groups.</p>
                            </>}
                            pointing
                            trapFocus
                            position={"after"}
                            align={"center"}
                            on="hover"
                          />
                        </Box>
                      </Grid>
                    </Grid>

                  </Box>
                  <Box className="border-left mx-2 h-100"></Box>

                  <Box>
                    <Grid
                      className="whRow mt-2"
                      columns={2}
                      styles={{
                        width: "100%",
                        marginTop: "20px",
                        alignItems: "center",
                        gridColumnGap: "8px",
                        gridTemplateColumns: "3fr 3fr",
                      }}
                    >
                      <Text content="Administration Access :" className="text-nowrap" style={{ fontWeight: "600" }} />
                      <Grid
                        columns={2}
                        styles={{
                          width: "100%",
                          gridColumnGap: "4px",
                          gridTemplateColumns: "1fr auto", alignItems: "center"
                        }}>
                        <Box>{administrativeAccessLoading ? (<Loader size="small" inline />) : (renderAdministrativeAccess())}</Box>
                        <Box className="text-secondary">
                          <Popup
                            trigger={<InfoIcon outline size="large" />}
                            content={<>
                              <p><b>Super Admin :</b> can manage settings <br />for the whole organization. <br />
                                <b>Admin :</b> can manage settings of <br />teams owned by this user. <br />
                                <b>No Admin :</b> no access to settings.
                              </p>
                            </>}
                            pointing
                            trapFocus
                            position={"after"}
                            align={"center"}
                            on="hover"
                          />
                        </Box>
                      </Grid>
                    </Grid>


                    {((props.user.administrativeAccess === 2 || props.user.administrativeAccessInherited === 2) && props.user.administrativeAccess !== 1 && props.user.administrativeAccessInherited !== 1) && (
                      <>
                        {/* <Grid
className="whRow mt-2"
columns={1}
styles={{
  width: "100%",
  marginTop: "20px",
  alignItems: "center",
  gridColumnGap: "8px",
  gridTemplateColumns: "1fr",
            }}
          >
           <Checkbox className="pl-0"
                           
                            label="Administrative access to your reporting network"
                          
                           checked={true}
                        />


</Grid>
<Grid
           className="whRow"
           columns={1}
           styles={{
             width: "100%",
             marginTop: "10px",
             alignItems: "center",
             gridColumnGap: "8px",
             gridTemplateColumns: "1fr",
          }}
          >
         
            <Text content="Add administrative access to people outside reporting network" weight="regular" />
           </Grid>
     */}
                        <Grid
                          className="whRow"
                          columns={2}
                          styles={{
                            width: "100%",
                            marginTop: "10px",
                            alignItems: "center",
                            gridColumnGap: "8px",
                            gridTemplateColumns: "3fr 6fr",
                          }}
                        >
                          <Text content="People :" />
                          <Grid
                            columns={2}
                            styles={{
                              width: "100%",
                              gridColumnGap: "4px",
                              gridTemplateColumns: "auto auto", alignItems: "center"
                            }}>
                            <Dropdown
                              fluid
                              styles={{ marginTop: "5px" }}
                              search
                              multiple
                              items={administrativePeople}
                              placeholder="Select People"
                              getA11ySelectionMessage={handleTeamSelectionPeople}
                              value={selectedPeople}
                              itemToValue={(obj) => {

                                return obj.key;
                              }}
                            // renderSelectedItem={(Component, props) => {
                            //   if (props.disabled) { 
                            //     props.className = props.className + " _disabledItem";
                            //     props.icon = null;
                            //   }
                            //   return <Component {...props}></Component>;
                            // }}
                            />
                            <Box className="text-secondary">
                              <Popup
                                trigger={<InfoIcon outline size="large" />}
                                content={<>
                                  <p>Administrative access  on <br />selected People.</p>
                                </>}
                                pointing
                                trapFocus
                                position={"after"}
                                align={"center"}
                                on="hover"
                              />
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid
                          className="whRow"
                          columns={2}
                          styles={{
                            width: "100%",
                            marginTop: "10px",
                            alignItems: "center",
                            gridColumnGap: "8px",
                            gridTemplateColumns: "3fr 6fr",
                          }}
                        >
                          <Text content="Teams :" />
                          <Grid
                            columns={2}
                            styles={{
                              width: "100%",
                              gridColumnGap: "4px",
                              gridTemplateColumns: "1fr auto", alignItems: "center"
                            }}>
                            <Dropdown
                              fluid
                              styles={{ marginTop: "5px" }}
                              search
                              multiple
                              items={teamsIsSetting}
                              placeholder="Select Teams"
                              getA11ySelectionMessage={handleTeamSelectionIsSetting}
                              value={selectedTeamsIsSetting}
                              itemToValue={(obj) => {
                                return obj.key;
                              }}
                              renderSelectedItem={(Component, props) => {
                                if (props.disabled) {
                                  props.className = props.className + " _disabledItem";
                                  props.icon = null;
                                }
                                return <Component {...props}></Component>;
                              }}
                            />
                            <Box className="text-secondary">
                              <Popup
                                trigger={<InfoIcon outline size="large" />}
                                content={<>
                                  <p>Administrative access  on <br />selected teams.</p>
                                </>}
                                pointing
                                trapFocus
                                position={"after"}
                                align={"center"}
                                on="hover"
                              />
                            </Box>
                          </Grid>
                        </Grid>




                        <Grid
                          className="whRow"
                          columns={2}
                          styles={{
                            width: "100%",
                            marginTop: "10px",
                            alignItems: "center",
                            gridColumnGap: "8px",
                            gridTemplateColumns: "3fr 6fr",
                          }}
                        >
                          <Text content="Groups :" />
                          <Grid
                            columns={2}
                            styles={{
                              width: "100%",
                              gridColumnGap: "4px",
                              gridTemplateColumns: "1fr auto", alignItems: "center"
                            }}>


                            <Dropdown
                              fluid
                              search
                              multiple
                              items={groupsIsSetting}
                              placeholder="Select Groups"
                              getA11ySelectionMessage={handleGroupSelectionIsSetting}
                              value={selectedGroupsIsSetting}
                              itemToValue={(obj) => {
                                return obj.key;
                              }}
                              renderSelectedItem={(Component, props) => {
                                if (props.disabled) {
                                  props.className = props.className + " _disabledItem";
                                  props.icon = null;
                                }
                                return <Component {...props}></Component>;
                              }}
                            />
                            <Box className="text-secondary">
                              <Popup
                                trigger={<InfoIcon outline size="large" />}
                                content={<>
                                  <p>Administrative access  on <br />selected groups.</p>
                                </>}
                                pointing
                                trapFocus
                                position={"after"}
                                align={"center"}
                                on="hover"
                              />
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid
                          className="whRow"
                          columns={2}
                          styles={{
                            width: "100%",
                            marginTop: "10px",
                            alignItems: "center",
                            gridColumnGap: "8px",
                            gridTemplateColumns: "3fr 6fr",
                          }}
                        >
                          <Text content="Call Queues :" />
                          <Grid
                            columns={2}
                            styles={{
                              width: "100%",
                              gridColumnGap: "4px",
                              gridTemplateColumns: "1fr auto", alignItems: "center"
                            }}>
                            <Dropdown
                              fluid
                              search
                              multiple
                              items={callQueuesIsSetting}
                              placeholder="Select Call Queues"
                              getA11ySelectionMessage={handleCallQueueSelectionIsSetting}
                              value={selectedCallQueuesIsSetting}
                              itemToValue={(obj) => {
                                return obj.key;
                              }}
                              renderSelectedItem={(Component, props) => {
                                if (props.disabled) {
                                  props.className = props.className + " _disabledItem";
                                  props.icon = null;
                                }
                                return <Component {...props}></Component>;
                              }}
                            />
                            <Box className="text-secondary">
                              <Popup
                                trigger={<InfoIcon outline size="large" />}
                                content={<>
                                  <p>Administrative access  on <br />selected call queues.</p>
                                </>}
                                pointing
                                trapFocus
                                position={"after"}
                                align={"center"}
                                on="hover"
                              />
                            </Box>
                          </Grid>
                          <Box
                            styles={{
                              height: "36px"
                            }}
                          >
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Box>
                </Grid>

              </>
            )}


          <Flex space="between" className="my-2" styles={{ position: `${context.mediaCategory.sm ? ".8em" : ""}`, bottom: "0px", right: "16px" }}>
            <Box />
            <Flex gap="gap.small">
              {
                (reportingAccess || props.user.reportingAccessInherited === 1 || props.user.administrativeAccess === 2
                  || props.user.administrativeAccessInherited === 2) &&
                <Button content="Save" primary onClick={submitPermissions} disabled={isDemo === true} />
              }
            </Flex>
          </Flex>

        </Box>

      )}
    </WindowContext>
  );
};

const TimePickerComponent = (props) => {
  return (
    <>
      <Text
        content={props.lableText}
        align="start"
        styles={{ marginRight: "8px" }}
      />
      {props.context.teams.hostClientType.web ||
        props.context.teams.hostClientType.desktop ? (
        <TeamsTimePicker
          value={props.startTime}
          onChange={(value) => {
            props.onStartTimeChange(value);
          }}
          required={true}
        />
      ) : (
        <NativeTimePicker
          id={props.day + "StartTime"}
          value={props.startTime}
          onChange={(value) => {
            props.onStartTimeChange(value);
          }}
          required={true}
        />
      )}
      <WindowMinimizeIcon />
      {props.context.teams.hostClientType.web ||
        props.context.teams.hostClientType.desktop ? (
        <TeamsTimePicker
          value={props.endTime}
          onChange={(value) => {
            props.onEndTimeChange(value);
          }}
          required={true}
        />
      ) : (
        <NativeTimePicker
          id={props.day + "EndTime"}
          value={props.endTime}
          onChange={(value) => {
            props.onEndTimeChange(value);
          }}
          required={true}
        />
      )}
    </>
  );
};

const EditOfficeHours = (props) => {
  const [loading, setLoading] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [mondayStart, setMondayStart] = useState("00:00");
  const [mondayEnd, setMondayEnd] = useState("00:00");
  const [tuesdayStart, setTuesdayStart] = useState("00:00");
  const [tuesdayEnd, setTuesdayEnd] = useState("00:00");
  const [wednesdayStart, setWednesdayStart] = useState("00:00");
  const [wednesdayEnd, setWednesdayEnd] = useState("00:00");
  const [thursdayStart, setThursdayStart] = useState("00:00");
  const [thursdayEnd, setThursdayEnd] = useState("00:00");
  const [fridayStart, setFridayStart] = useState("00:00");
  const [fridayEnd, setFridayEnd] = useState("00:00");
  const [saturdayStart, setSaturdayStart] = useState("00:00");
  const [saturdayEnd, setSaturdayEnd] = useState("00:00");
  const [sundayStart, setSundayStart] = useState("00:00");
  const [sundayEnd, setSundayEnd] = useState("00:00");

  useEffect(() => {
    loadTemplateData();
    loadUserTimeDetail();
  }, [props.user]);

  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));
  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, []);

  const loadTemplateData = () => {
    setLoading(true);
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetTemplates(token).then((response) => {
        if (response != undefined && response != "") {
          var tempData = response.map((element) => {
            var ob = Object.assign({}, element);
            ob.header = ob.name;
            ob.key = ob.templateGUID;
            return ob;
          });
          setTemplateData(tempData);
        }
        setLoading(false);
      });
    });
  };

  const loadUserTimeDetail = () => {
    setLoading(true);
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetUserWorkingHours(
        props.user.userGUID,
        token
      ).then((response) => {
        if (response != undefined && response != "") {
          response.forEach((item) => {
            if (item.dayOfWeekId === 0) {
              setSundayEnd(item.endTime);
              setSundayStart(item.startTime);
            } else if (item.dayOfWeekId === 1) {
              setMondayEnd(item.endTime);
              setMondayStart(item.startTime);
            } else if (item.dayOfWeekId === 2) {
              setTuesdayEnd(item.endTime);
              setTuesdayStart(item.startTime);
            } else if (item.dayOfWeekId === 3) {
              setWednesdayEnd(item.endTime);
              setWednesdayStart(item.startTime);
            } else if (item.dayOfWeekId === 4) {
              setThursdayEnd(item.endTime);
              setThursdayStart(item.startTime);
            } else if (item.dayOfWeekId === 5) {
              setFridayEnd(item.endTime);
              setFridayStart(item.startTime);
            } else if (item.dayOfWeekId === 6) {
              setSaturdayEnd(item.endTime);
              setSaturdayStart(item.startTime);
            }
          });
        }
        setLoading(false);
      });
    });
  };

  const getA11ySelectionMessage = {
    onAdd: (item) => {
      setLoading(true);
      var timeData = item.timeDetails;
      timeData.forEach((item) => {
        if (item.dayOfWeekId === 0) {
          setSundayEnd(item.endTime);
          setSundayStart(item.startTime);
        } else if (item.dayOfWeekId === 1) {
          setMondayEnd(item.endTime);
          setMondayStart(item.startTime);
        } else if (item.dayOfWeekId === 2) {
          setTuesdayEnd(item.endTime);
          setTuesdayStart(item.startTime);
        } else if (item.dayOfWeekId === 3) {
          setWednesdayEnd(item.endTime);
          setWednesdayStart(item.startTime);
        } else if (item.dayOfWeekId === 4) {
          setThursdayEnd(item.endTime);
          setThursdayStart(item.startTime);
        } else if (item.dayOfWeekId === 5) {
          setFridayEnd(item.endTime);
          setFridayStart(item.startTime);
        } else if (item.dayOfWeekId === 6) {
          setSaturdayEnd(item.endTime);
          setSaturdayStart(item.startTime);
        }
      });
      setLoading(false);
    },
  };

  const submitForm = () => {
    setLoading(true);
    var data = [
      {
        userGUID: props.user.userGUID,
        dayOfWeekId: 0,
        startTime: sundayStart,
        endTime: sundayEnd,
      },
      {
        userGUID: props.user.userGUID,
        dayOfWeekId: 1,
        startTime: mondayStart,
        endTime: mondayEnd,
      },
      {
        userGUID: props.user.userGUID,
        dayOfWeekId: 2,
        startTime: tuesdayStart,
        endTime: tuesdayEnd,
      },
      {
        userGUID: props.user.userGUID,
        dayOfWeekId: 3,
        startTime: wednesdayStart,
        endTime: wednesdayEnd,
      },
      {
        userGUID: props.user.userGUID,
        dayOfWeekId: 4,
        startTime: thursdayStart,
        endTime: thursdayEnd,
      },
      {
        userGUID: props.user.userGUID,
        dayOfWeekId: 5,
        startTime: fridayStart,
        endTime: fridayEnd,
      },
      {
        userGUID: props.user.userGUID,
        dayOfWeekId: 6,
        startTime: saturdayStart,
        endTime: saturdayEnd,
      },
    ];
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserWorkingHours(data, token).then(
        (response) => {
          setLoading(false);
        }
      );
    });
  };

  return (
    <WindowContext.Consumer>
      {(context) =>
        loading ? (
          <Loader label="Loading..." />
        ) : (
          <Box>
            <Form
              className="edit_user_officeHoursForm h-0"
              onSubmit={submitForm}
              styles={{ marginTop: "0px", fontSize: "0.9rem" }}
            ><div><Text
              content="Time Zone : "
              styles={{ marginRight: "8px" }}
            /><span>{props.user.timezoneID}</span></div>
              <Grid
                columns={4}
                styles={{
                  width: "100%",
                  alignItems: "center",
                  gridColumnGap: "8px",
                  gridTemplateColumns:
                    "94px calc(50% - 72px) 25px calc(50% - 72px)",
                  marginBottom: "0.6rem !important",
                }}
              >
                <TimePickerComponent
                  lableText={"Monday : "}
                  day={"monday"}
                  context={context}
                  startTime={mondayStart}
                  endTime={mondayEnd}
                  onStartTimeChange={(value) => {
                    setMondayStart(value);
                  }}
                  onEndTimeChange={(value) => {
                    setMondayEnd(value);
                  }}
                />
              </Grid>
              <Grid
                columns={4}
                styles={{
                  width: "100%",
                  alignItems: "center",
                  gridColumnGap: "8px",
                  gridTemplateColumns:
                    "94px calc(50% - 72px) 25px calc(50% - 72px)",
                  marginBottom: "0.6rem !important",
                }}
              >
                <TimePickerComponent
                  lableText={"Tuesday : "}
                  day={"tuesday"}
                  context={context}
                  startTime={tuesdayStart}
                  endTime={tuesdayEnd}
                  onStartTimeChange={(value) => {
                    setTuesdayStart(value);
                  }}
                  onEndTimeChange={(value) => {
                    setTuesdayEnd(value);
                  }}
                />
              </Grid>
              <Grid
                columns={4}
                styles={{
                  width: "100%",
                  alignItems: "center",
                  gridColumnGap: "8px",
                  gridTemplateColumns:
                    "94px calc(50% - 72px) 25px calc(50% - 72px)",
                  marginBottom: "0.6rem !important",
                }}
              >
                <TimePickerComponent
                  lableText={"Wednesday : "}
                  day={"wednesday"}
                  context={context}
                  startTime={wednesdayStart}
                  endTime={wednesdayEnd}
                  onStartTimeChange={(value) => {
                    setWednesdayStart(value);
                  }}
                  onEndTimeChange={(value) => {
                    setWednesdayEnd(value);
                  }}
                />
              </Grid>
              <Grid
                columns={4}
                styles={{
                  width: "100%",
                  alignItems: "center",
                  gridColumnGap: "8px",
                  gridTemplateColumns:
                    "94px calc(50% - 72px) 25px calc(50% - 72px)",
                  marginBottom: "0.6rem !important",
                }}
              >
                <TimePickerComponent
                  lableText={"Thursday : "}
                  day={"thursday"}
                  context={context}
                  startTime={thursdayStart}
                  endTime={thursdayEnd}
                  onStartTimeChange={(value) => {
                    setThursdayStart(value);
                  }}
                  onEndTimeChange={(value) => {
                    setThursdayEnd(value);
                  }}
                />
              </Grid>
              <Grid
                columns={4}
                styles={{
                  width: "100%",
                  alignItems: "center",
                  gridColumnGap: "8px",
                  gridTemplateColumns:
                    "94px calc(50% - 72px) 25px calc(50% - 72px)",
                  marginBottom: "0.6rem !important",
                }}
              >
                <TimePickerComponent
                  lableText={"Friday : "}
                  day={"friday"}
                  context={context}
                  startTime={fridayStart}
                  endTime={fridayEnd}
                  onStartTimeChange={(value) => {
                    setFridayStart(value);
                  }}
                  onEndTimeChange={(value) => {
                    setFridayEnd(value);
                  }}
                />
              </Grid>
              <Grid
                columns={4}
                styles={{
                  width: "100%",
                  alignItems: "center",
                  gridColumnGap: "8px",
                  gridTemplateColumns:
                    "94px calc(50% - 72px) 25px calc(50% - 72px)",
                  marginBottom: "0.6rem !important",
                }}
              >
                <TimePickerComponent
                  lableText={"Saturday : "}
                  day={"saturday"}
                  context={context}
                  startTime={saturdayStart}
                  endTime={saturdayEnd}
                  onStartTimeChange={(value) => {
                    setSaturdayStart(value);
                  }}
                  onEndTimeChange={(value) => {
                    setSaturdayEnd(value);
                  }}
                />
              </Grid>
              <Grid
                columns={4}
                styles={{
                  width: "100%",
                  alignItems: "center",
                  gridColumnGap: "8px",
                  gridTemplateColumns:
                    "94px calc(50% - 72px) 25px calc(50% - 72px)",
                  marginBottom: "0.6rem !important",
                }}
              >
                <TimePickerComponent
                  lableText={"Sunday : "}
                  day={"sunday"}
                  context={context}
                  startTime={sundayStart}
                  endTime={sundayEnd}
                  onStartTimeChange={(value) => {
                    setSundayStart(value);
                  }}
                  onEndTimeChange={(value) => {
                    setSundayEnd(value);
                  }}
                />
              </Grid>

              <Grid
                className="whRow"
                columns={2}
                styles={{
                  width: "100%",
                  marginTop: "10px",
                  alignItems: "center",
                  gridColumnGap: "8px",
                  gridTemplateColumns: "130px calc(100% - 145px)",
                  marginBottom: "0.5rem !important",
                  marginTop: "0.5rem",
                }}
              >
                <Text
                  content="Update Schedule : "
                  styles={{ marginRight: "8px" }}
                />
                <Dropdown
                  search
                  items={templateData}
                  placeholder="select a profile"
                  getA11ySelectionMessage={getA11ySelectionMessage}
                  itemToValue={(obj) => {
                    return obj.key;
                  }}
                />
              </Grid>
              <Flex space="between" styles={{ marginTop: "0.6rem" }}>
                <Box />
                <Flex gap="gap.small">
                  <Button content="Save" type="submit" primary disabled={isDemo === true} />
                </Flex>
              </Flex>
            </Form>
          </Box>
        )
      }
    </WindowContext.Consumer>
  );
};

const EditUser = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("profile");
  const [focused, setFocused] = useState(0);

  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));
  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, []);

  return (
    <WindowContext.Consumer>
      {(context) =>
        loading ? (
          <Loader label="Loading..." />
        ) : (
          <Box styles={{ height: "100%", position: "relative" }}>
            <Grid
              styles={{ overflow: "auto" }}
              className="user-profile-header"
              columns={2}
            >
              <Box styles={{ minWidth: "max-content" }}>
                <Layout
                  gap="0.6rem"
                  start={
                    <Avatar
                      image={props.user.userImage}
                      size={context.mediaCategory.sm ? "large" : "larger"}
                      name={props.user.userName}
                    />
                  }
                  main={
                    <div className="user-info">
                      <Text
                        content={props.user.userName}
                        size={context.mediaCategory.sm ? "medium" : "large"}
                      />
                      <Text
                        content={props.user.jobTitle}
                        size={context.mediaCategory.sm ? "small" : "medium"}
                        timestamp
                      />
                      <Text
                        content={props.user.email}
                        size={context.mediaCategory.sm ? "small" : "medium"}
                        timestamp
                      />
                    </div>
                  }
                  end=""
                  styles={{ marginTop: "0" }}
                />
              </Box>
              <Box styles={{ minWidth: "max-content" }}>
                {(selectedTab === "profile" && (props.user.managerName != undefined &&
                  props.user.managerName.trim() != "")) ? (
                  <Box>
                    <Text
                      styles={{
                        color: "#666",
                        lineHeight: 1,
                        marginLeft: "5px",
                      }}
                      content="Manager"
                    />
                    <Layout
                      gap="0.6rem"
                      start={
                        <Avatar
                          image={props.user.managerPhoto}
                          size={
                            context.mediaCategory.sm ? "medium" : "large"
                          }
                          name={props.user.managerName}
                        />
                      }
                      main={
                        <div className="user-info">
                          <Box className="">
                            <Text
                              content={props.user.managerName}
                              size={
                                context.mediaCategory.sm
                                  ? "small"
                                  : "medium"
                              }
                            />
                          </Box>
                          <Text
                            content={props.user.managerJobTitle}
                            size={
                              context.mediaCategory.sm ? "smaller" : "small"
                            }
                            timestamp
                          />
                        </div>
                      }
                      end=""
                      style={{
                        marginTop: "0.5rem !important",
                        //marginBottom: "0.5rem", (adds unecessary scroll in profile section of manager)
                      }}
                    />
                  </Box>
                ) : (
                  <Box />
                )}
              </Box>
            </Grid>
            <Flex
              className="addcardcontainer"
              column
              gap="gap.small"
              styles={{ marginTop: "1rem", height: "calc(100% - 72px)" }}
            >
              <div className="editBtnGroup align-items-center">
                <Button
                  content="Profile"
                  onClick={() => { setSelectedTab("profile"); setFocused(0); }}
                  primary={selectedTab === "profile"}
                />
                <Button
                  content="Permissions"
                  onClick={() => {
                    setSelectedTab("permissions");
                    setFocused(0);
                    setTimeout(function () {
                      document
                        .querySelectorAll(".k-dialog .ui-input__input")
                        .forEach((item) => {
                          item.addEventListener("keydown", function (e) {
                            if (e.keyCode === 32) {
                              e.stopPropagation();
                            }
                          });
                        });
                    }, 1000);
                  }}
                  primary={selectedTab === "permissions"}
                />
                <Button
                  content="Working Hours"
                  onClick={() => { setSelectedTab("workinghours"); setFocused(0); }}
                  primary={selectedTab === "workinghours"}
                />
                {isDemo && !context.mediaCategory.sm && <div className="col text-right px-0">{selectedTab === "profile" ? "Exploring the app: Edits not available." : "Exploring app with sample data."}</div>}

              </div>
              {isDemo && context.mediaCategory.sm && <div className="col text-right px-0 mb-1" style={{ fontSize: ".9rem" }}>{selectedTab === "profile" ? "Exploring the app: Edits not available." : "Exploring app with sample data."}</div>}

              <Segment
                className="edit_user_container card-bdr-top"
                styles={{ minWidth: "400px", maxHeight: "100%", overflow: `${context.mediaCategory.sm ? "auto" : ""}` }}
              > {selectedTab === "profile" && <EditProfile {...props} user={props.user} focused={focused} />}
                {selectedTab === "permissions" && (
                  <EditPermissions
                    {...props}
                    handleProfileClick={(focused) => {
                      setFocused(focused);
                      setSelectedTab("profile");
                    }}
                  />
                )}
                {selectedTab === "workinghours" && (
                  <EditOfficeHours user={props.user} />
                )}
              </Segment>
            </Flex>
          </Box>
        )
      }
    </WindowContext.Consumer>
  );
};

export default EditUser;
